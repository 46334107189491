import { DeviceInfo } from './../models/device-info';
import { ResponseGetRobinetterie } from './../models/responseGetRobinetterie';
import { SentDeclarationResponse } from './../models/sent-declaration-response';
import { InstallationDeclaration } from './../models/installation-declaration';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { PromotionReglementeeModel } from '../models/promotion-reglementee.model';
import { PromotionClassiqueModel } from '../models/promotion-classique.model';

@Injectable({
  providedIn: 'root'
})
export class DeclarationService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  declareInstallation(declaration: InstallationDeclaration): Observable<SentDeclarationResponse> {
    return this.http.post<SentDeclarationResponse>(this.baseUrl + 'api/declaration/declareInstallation', declaration);
  }

  declareInstallationWithPromotion(fd: FormData): Observable<SentDeclarationResponse> {
    return this.http.post<SentDeclarationResponse>(this.baseUrl + 'api/declaration/declareInstallationWithPromotion', fd);
  }

  declareCommissioning(fd: FormData): Observable<SentDeclarationResponse> {
    return this.http.post<SentDeclarationResponse>(this.baseUrl + 'api/declaration/declareCommissioning', fd);
  }

  checkNumeroPlaqueRobinetterie(numeroSerie: string): Observable<ResponseGetRobinetterie> {
    return this.http.get<ResponseGetRobinetterie>(this.baseUrl
      + 'api/declaration/checkNumeroPlaqueRobinetterie?numeroSerie=' + numeroSerie);
  }

  checkSerialNumber(serialNumber: string, declarationType: string, installationDate: string | null = null): Observable<DeviceInfo> {
    let queryParams = `serialNumber=${serialNumber}&declarationType=${declarationType}`;
    if (installationDate !== null) {
      queryParams += `&installationDate=${installationDate}`;
    }
    return this.http.get<DeviceInfo>(this.baseUrl + `api/declaration/checkSerialNumber?${queryParams}`);
  }

  getEligiblePromotionsReglementees(serialNumber: string, installationDate: string): Observable<PromotionReglementeeModel[]> {
    return this.http.get<PromotionReglementeeModel[]>(this.baseUrl + `api/declaration/getEligiblePromotionsReglementees?serialNumber=${serialNumber}&installationDate=${installationDate}`);
  }

  getEligiblePromotionsClassiques(serialNumber: string, installationDate: string): Observable<PromotionClassiqueModel[]> {
    return this.http.get<PromotionClassiqueModel[]>(this.baseUrl + `api/declaration/getEligiblePromotionsClassiques?serialNumber=${serialNumber}&installationDate=${installationDate}`);
  }
}
