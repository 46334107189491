import { ImprintComponent } from './components/imprint/imprint.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { EnsureModuleLoadedOnceGuard } from './ensure-module-loaded-once.guard';
import { AuthorizeInterceptor } from './authorize.interceptor';
import { SecretInterceptor } from './secret.interceptor';
import { LoginComponent } from './components/login/login.component';
import { LoginMenuComponent } from './components/login-menu/login-menu.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { NgModule, LOCALE_ID, Optional, SkipSelf } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from './http-error.interceptor';

import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';
import { ConfigService } from './services/config.service';

import { AuthModule, StsConfigLoader } from 'angular-auth-oidc-client';
import { NgxPermissionsModule } from 'ngx-permissions';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { FooterComponent } from './components/footer/footer.component';
import { LogoutComponent } from './components/logout/logout.component';


// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');

const httpLoaderFactory = (configService: ConfigService) => configService.load();

@NgModule({
  declarations: [
    NavMenuComponent,
    LoginMenuComponent,
    LoginComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    LogoutComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' },
    { provide: HTTP_INTERCEPTORS, useClass: SecretInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    ConfigService
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule,
    BrowserAnimationsModule,
    SharedModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoaderFactory,
        deps: [ConfigService]
      }
    }),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(), // forRoot ?
  ],
  exports: [
    NavMenuComponent,
    FooterComponent,
    LoginMenuComponent,
  ]
})

export class CoreModule extends EnsureModuleLoadedOnceGuard {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
     super(parentModule);
  }
}
