<div [class.disabled]="isSubmitting" class="container-fluid declaration">

    <div class="declaration__header d-flex flex-row">
        <svg routerLink="/declaration" class="declaration__icon declaration__icon--back-menu"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                d="M176 96c.002 13.242-5.379 25.268-14.059 33.941-8.673 8.68-20.699 14.06-33.941 14.059H56v-8h72c11.059-.002 21.033-4.47 28.284-11.716C163.53 117.034 167.998 107.058 168 96c-.002-11.059-4.47-21.033-11.716-28.284C149.034 60.47 139.06 56.002 128 56H34.657l21.171 21.172-5.656 5.656L19.343 52l30.829-30.828 5.656 5.656L34.657 48H128c13.242-.002 25.268 5.379 33.941 14.059C170.621 70.732 176.002 82.758 176 96z" />
        </svg>
        <div class="declaration__title">
            <h1 class="">Nouvelle déclaration d'installation</h1>
            <p>Déclarer l'installation d'un matériel Bosch ou elm.leblanc.</p>
        </div>
    </div>
    <form [formGroup]="declarationForm" (ngSubmit)="onSubmit()" class="declaration__form form col-xl-10">
        <div *ngIf="hasSubmitError" class="alert alert-danger mt-2">
            Une erreur interne est survenue. La déclaration n'a pas pu être envoyée. <br><span
                style="font-style: italic;">Erreur {{errorStatus}}, ({{errorStatusText}}</span>)
        </div>
        <div *ngIf="hasPostSubmitError" class="alert alert-danger mt-2">
            {{errorStatusText}}
        </div>
        <div class="form__section">
            <h2>Informations installation</h2>

            <div class="form-group row form__item">
                <label for="numeroSerie" class="col-sm-4 col-form-label">Numéro de série*</label>
                <div class="col-sm-8">
                    <div class="d-flex flex-column flex-lg-row align-items-center">
                        <input
                            required
                            id="numeroSerie"
                            type="text"
                            maxlength="26"
                            class="form-control"
                            placeholder="XXXX-XXX-XXXXXX-XXXXXXXXXX"
                            formControlName="numeroSerie"
                            [value]="d.numeroSerie.value | serialNumber"
                        >
                        <app-scan-modal class="ml-auto mt-2 mt-lg-0" [whatToScan]="'le code barre de l\'appareil'"
                            (serialNumber)="setSerialNumberFromScan($event)">
                        </app-scan-modal>
                    </div>
                    <div [hidden]="d.numeroSerie.valid || d.numeroSerie.pristine" class="mt-1 alert alert-danger">
                        <div *ngIf="d.numeroSerie.errors?.required">Le numéro de série de l'appareil est requis.</div>
                        <div *ngIf="d.numeroSerie.errors?.pattern">
                            Le numéro de série saisi n'a pas le bon format (XXXX-XXX-XXXXXX-XXXXXXXXXX).
                        </div>
                    </div>
                    <ng-container *ngIf="d.numeroSerie.valid">
                        <div *ngIf="deviceInfo$ | async as deviceInfo; else loadingDeviceInfo" class="mt-2">
                            <ng-container *ngIf="deviceInfo.isValid; else notValid">
                                <p>Marque : {{deviceInfo.brand}}</p>
                                <p>Modèle : {{deviceInfo.deviceName}}</p>
                                <p *ngIf="deviceInfo.points > 0">Points Degré+ : {{deviceInfo.points}}</p>
                            </ng-container>
                            <ng-template #notValid>
                                <p class="text-danger">Ce numéro de série ne correspond à aucun appareil connu.</p>
                            </ng-template>
                        </div>
                        <ng-template #loadingDeviceInfo>
                            <div class="spinner-border mt-2" role="status">
                                <span class="sr-only">Chargement...</span>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <div class="form-group row form__item">
                <label class="col-sm-4 col-form-label">Ajouter un dosseret à la déclaration ?</label>
                <div class="col-sm-8">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1"
                            value="1" (change)="onDisplay()" [checked]="isSelected">
                        <label class="form-check-label" for="inlineRadio1">Oui</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2"
                            value="2" (change)="onDisplayFalse()" checked>
                        <label class="form-check-label" for="inlineRadio2">Non</label>
                    </div>
                </div>
            </div>
            <div *ngIf="isDisplay" class="form-group row form__item mb-4">
                <label for="numeroPlaqueRobinet" class="col-sm-4 col-form-label">Numéro de série du dosseret</label>
                <div class="col-sm-8">
                    <div class="d-flex flex-column flex-lg-row align-items-center">
                        <input
                            formControlName="numeroPlaqueRobinet"
                            id="numeroPlaqueRobinet"
                            type="text"
                            maxlength="26"
                            class="form-control"
                            placeholder="XXXX-XXX-XXXXXX-XXXXXXXXXX"
                            [value]="d.numeroPlaqueRobinet.value | serialNumber"
                        >
                        <app-scan-modal class="ml-auto mt-2 mt-lg-0" [whatToScan]="'le code barre du dosseret'"
                            (serialNumber)="setDosseretSerialNumberFromScan($event)">
                        </app-scan-modal>
                    </div>
                    <div [hidden]="d.numeroPlaqueRobinet.valid || d.numeroPlaqueRobinet.pristine"
                        class="alert alert-danger">
                        <div *ngIf="d.numeroPlaqueRobinet.errors?.required">
                            Le numéro de série du dosseret est requis.
                        </div>
                        <div *ngIf="d.numeroPlaqueRobinet.errors?.pattern">
                            Le numéro de série saisi n'a pas le bon format (XXXX-XXX-XXXXXX-XXXXXXXXXX).
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="dateInstallation" class="col-sm-4 col-form-label">Date d'installation*</label>
                <div class="col-sm-8">
                    <input
                        required
                        [max]="today | date:'yyyy-MM-dd'"
                        [min]="minDate | date:'yyyy-MM-dd'"
                        id="dateInstallation"
                        type="date"
                        class="form-control"
                        formControlName="dateInstallation"
                    >
                    <div [hidden]="d.dateInstallation.valid || d.dateInstallation.pristine" class="alert alert-danger">
                        <div *ngIf="d.dateInstallation.errors?.required">
                            La date d'installation de l'appareil est requise.
                        </div>
                        <div *ngIf="d.dateInstallation.errors?.afterMax">
                            La date d'installation de l'appareil doit être inférieure ou égale à la date du jour.
                        </div>
                        <div *ngIf="d.dateInstallation.errors?.beforeMin">
                            La date d'installation de l'appareil est trop ancienne.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="dateFactureAchat" class="col-sm-4 col-form-label">Date facture d'achat*</label>
                <div class="col-sm-8">
                    <input
                        required
                        [max]="today | date:'yyyy-MM-dd'"
                        id="dateFactureAchat"
                        type="date"
                        class="form-control"
                        formControlName="dateFactureAchat"
                    >
                    <div [hidden]="d.dateFactureAchat.valid || d.dateFactureAchat.pristine" class="alert alert-danger">
                        <div *ngIf="d.dateFactureAchat.errors?.required">
                            La date de l'achat de l'appareil est requise.
                        </div>
                        <div *ngIf="d.dateFactureAchat.errors?.afterMax">
                            La date de l'achat de l'appareil doit être inférieure ou égale à la date du jour.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="departement" class="col-sm-4 col-form-label">Département*</label>
                <div class="col-sm-8">
                    <select
                        required
                        id="departement"
                        class="form-control"
                        formControlName="departement"
                    >
                        <option *ngFor="let dep of departements" [value]="dep.code+' - '+dep.nom">
                            {{dep.code}} - {{dep.nom}}
                        </option>
                    </select>
                    <div [hidden]="d.departement.valid || d.departement.pristine" class="alert alert-danger">
                        La département d'achat est requis.
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="distributeur" class="col-sm-4 col-form-label">Distributeur*</label>
                <div class="col-sm-8">
                    <input
                        required
                        id="distributeur"
                        type="text"
                        class="form-control"
                        formControlName="distributeur"
                    >
                    <div [hidden]="d.distributeur.valid || d.distributeur.pristine" class="alert alert-danger">
                        Le distributeur est requis.
                    </div>
                </div>
            </div>
        </div>
        <div class="form__section">
            <h2>Lieu d'installation</h2>
            <div class="form-group row form__item">
                <label for="address" class="col-sm-4 col-form-label">Adresse*</label>
                <div class="col-sm-8">
                    <input
                        required
                        id="address"
                        type="text"
                        class="form-control"
                        formControlName="adresseInstallation"
                    >
                    <div
                        [hidden]="d.adresseInstallation.valid || d.adresseInstallation.pristine"
                        class="alert alert-danger"
                    >
                        L'adresse d'installation est requise.
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="ville" class="col-sm-4 col-form-label">Ville*</label>
                <div class="col-sm-8">
                    <input
                        required
                        id="ville"
                        type="text"
                        class="form-control"
                        formControlName="villeInstallation"
                    >
                    <div [hidden]="d.villeInstallation.valid || d.villeInstallation.pristine" class="alert alert-danger">
                        La ville d'installation est requise.
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="codePostal" class="col-sm-4 col-form-label">Code postal*</label>
                <div class="col-sm-8">
                    <input
                        required
                        id="codePostal"
                        type="text"
                        class="form-control"
                        formControlName="codePostalInstallation"
                    >
                    <div
                        [hidden]="d.codePostalInstallation.valid || d.codePostalInstallation.pristine"
                        class="alert alert-danger"
                    >
                        <div *ngIf="d.codePostalInstallation.errors?.required">
                            Le code postal d'installation est requis.
                        </div>
                        <div *ngIf="d.codePostalInstallation.errors?.pattern">
                            Le code doit compter 5 chiffres.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ng-container *ngIf="eligiblePromotions && eligiblePromotions.length">
            <div class="form__section">
                <h2>Vous êtes éligible à une offre promotionnelle !</h2>
                <form [formGroup]="promoForm" (ngSubmit)="return">
                    <div class="form-group row form__item">
                        <label class="col-sm-4 col-form-label">Souhaitez-vous participer ?</label>
                        <div class="col-sm-8">
                            <div class="form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="participate"
                                    formControlName="participate"
                                    [value]="'yes'"
                                    required
                                />
                                <label class="form-check-label" for="participate">
                                    Oui
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    id="participate"
                                    formControlName="participate"
                                    [value]="'no'"
                                    required
                                />
                                <label class="form-check-label" for="participate">
                                    Non
                                </label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="f.participate.value === 'yes'">
                        <div class="form-group row form__item">
                            <label for="promo" class="col-sm-4 col-form-label">
                                Opération choisie*
                            </label>
                            <div class="col-sm-8">
                                <select
                                    id="promo"
                                    name="promo"
                                    formControlName="promotionId"
                                    class="form-control"
                                    [ngClass]="{ 'is-invalid': f.promotionId.errors }"
                                    required
                                >
                                    <option ></option>
                                    <option *ngFor="let operation of eligiblePromotions" [value]="operation.idPromotion">
                                        {{ operation.name }}
                                    </option>

                                </select>
                            </div>
                        </div>
                        <ng-container *ngIf="f.promotionId.value && chosenPromotion">
                            <div [innerHtml]="chosenPromotion.label"></div>
                        </ng-container>
                        <ng-container *ngIf="f.promotionId.value && giftList?.length">
                            <div class="form-group row form__item">
                                <label for="gift" class="col-sm-4 col-form-label">
                                    Cadeau*
                                </label>
                                <div class="col-sm-8">
                                    <select
                                        id="gift"
                                        name="gift"
                                        formControlName="giftId"
                                        class="form-control"
                                        [ngClass]="{ 'is-invalid': f.giftId.errors }"
                                        required
                                    >
                                        <option *ngFor="let gift of giftList" [value]="gift.id">
                                            {{ gift.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="chosenPromotion.purchaseProofRequired" class="form-group row form__item">
                                <label for="image" class="col-sm-4 col-form-label">Preuve d'achat*</label>
                                <div class="col-sm-8">
                                    <input
                                        type="file"
                                        id="image"
                                        accept="image/jpeg,image/png,application/pdf"
                                        formControlName="purchaseProofFile"
                                        class="form-control-file"
                                        [ngClass]="{ 'is-invalid': f.purchaseProofFile.errors }"
                                        (change)="onFileChange($event)"
                                    />
                                    <small><em>Le fichier doit être au format jpeg, png ou pdf.</em></small>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                </form>
            </div>
        </ng-container>

        <span class="form__note">Les champs marqués d'un astérisque (*) sont obligatoires.</span>
        <button [disabled]="declarationForm.invalid || isSubmitting || isCheckingPromotions || !isPromoFormValid"
            class="d-block ml-auto col-4 col-xl-2 form__button">Enregistrer</button>
    </form>
</div>

<div *ngIf="isSubmitting" class="loading">
    <span class="loading__title">Envoi de la déclaration...</span>
    <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
</div>
