import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { SharedModule } from './../shared/shared.module';
import { SucceededDeclarationComponent } from './succeeded-declaration/succeeded-declaration.component';
import { ScanModalComponent } from './scan-modal/scan-modal.component';
import { DeclarationHistoryComponent } from './declaration-history/declaration-history.component';
import { InstallationDeclarationComponent } from './installation-declaration/installation-declaration.component';
import { CommissioningDeclarationComponent } from './commissioning-declaration/commissioning-declaration.component';
import { ChooseDeclarationComponent } from './choose-declaration/choose-declaration.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    ChooseDeclarationComponent,
    CommissioningDeclarationComponent,
    InstallationDeclarationComponent,
    DeclarationHistoryComponent,
    ScanModalComponent,
    SucceededDeclarationComponent],
  imports: [
    CommonModule,
    ZXingScannerModule,
    SharedModule,
  ]
})
export class DeclarationModule { }
