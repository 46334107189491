import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

@Component({
  selector: 'points-notification',
  templateUrl: './points-notification.component.html',
  styleUrls: ['./points-notification.component.scss']
})
export class PointsNotificationComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data : any) { }

  ngOnInit(): void {
  }

}
