<table class="bill-recap__table table border-bottom" [ngClass]="{'sticky-top': isSticky}">
    <thead>
        <tr>
            <th scope="col">Type d'appareil</th>
            <th scope="col">Qté</th>
            <th scope="col">P. N. HT</th>
            <th scope="col">Montant</th>
            <th></th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let recap of billRecap; let i = index">
            <tr class="table__productType" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed[i]"
                aria-controls="collapseExample">
                <td class="table__designation">{{recap.productType}}</td>
                <td>{{recap.numberOfItems}}</td>
                <td>{{recap.unitPrice}}</td>
                <td>{{recap.totalPrice}}</td>
                <td>
                    <svg class="table__logoDown" version="1.1" id="Icon_x5F_contour" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 192 192"
                        xml:space="preserve">
                        <path
                            d="M96,123.98438L51.17139,79.15625l5.65723-5.65723L96,112.6709l39.17139-39.17188l5.65723,5.65723L96,123.98438z" />
                    </svg>
                </td>
            </tr>
            <tr #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed[i]">
                <td colspan="5">
                    <app-declaration-table [declarations]="declarations" [productType]="recap.productType">
                    </app-declaration-table>
                </td>
            </tr>
        </ng-container>
        <tr *ngIf="billRecap.length > 0" class="table__total">
            <td>TOTAL</td>
            <td>{{getTotalNumberOfDevices()}}</td>
            <td>-</td>
            <td>{{getTotalPrice()}}</td>
            <td></td>
        </tr>
    </tbody>
</table>

<!-- <p>
    <button type="button" class="btn btn-outline-primary" (click)="collapse.toggle()"
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
        Toggle
    </button>
</p>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
    <div class="card">
        <div class="card-body">
            You can collapse this card by clicking Toggle
        </div>
    </div>
</div> -->