import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { SecretService } from './services/secret.service';

@Injectable()
export class SecretInterceptor implements HttpInterceptor {

  constructor(private secretService: SecretService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(this.addSecret(request));
  }

  private addSecret(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a secret or the endpoint then we should not set the secret.
    if (!this.secretService.clientSecret || !this.secretService.tokenEndpoint) {
      return request;
    }
    // If we are not calling token endpoint then do not add the secret.
    if (!request.url.startsWith(this.secretService.tokenEndpoint)) {
      return request;
    }
    return request.clone({
      //body: request.body + `&client_secret=${this.secretService.clientSecret}`
      body: request.body
    });
  }
}
