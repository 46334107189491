import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-scan-modal',
  templateUrl: './scan-modal.component.html',
  styleUrls: ['./scan-modal.component.scss']
})
export class ScanModalComponent {

  closeResult = '';
  @Output() serialNumber : EventEmitter<string> = new EventEmitter<string>();
  @Input() whatToScan : string;

  constructor(private modalService : NgbModal) { }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  scanSuccessHandler(event, modal){
    console.log("scan success", event);
    this.serialNumber.emit(this.addDashesToSerialNumber(event));
    modal.dismiss('Cross click');
  }

  addDashesToSerialNumber(serialNumberFromScan : string) : string{
    return `${serialNumberFromScan.substring(0, 4)}-${serialNumberFromScan.substring(4, 7)}-${serialNumberFromScan.substring(7, 13)}-${serialNumberFromScan.substring(13, 23)}`
  }
}
