<div class="bill-detail">

    <!-- <div *ngIf="!isLoading && !isSending" class="bill-detail__content"> -->
    <div *ngIf="billDetail$ | async as billDetail; else loading" class="bill-detail__content">
        <h1>Récapitulatif</h1>

        <app-bill-recap-table [declarations]="billDetail.declarations" [priceScale]="billDetail.bareme">
        </app-bill-recap-table>

        <div class="my-3">
            <h2>Statut de votre facture : {{billDetail.statut}}</h2>
        </div>

        <div *ngIf="billDetail.statut == 'Facture refusée' && !!billDetail.motifRefus" class="my-2">
            <h2>Motif du refus</h2>
            <textarea class="form-control" rows="3" readonly>{{billDetail.motifRefus}}</textarea>
        </div>

        <div *ngIf="!!billDetail.commentaire" class="my-2">
            <h2>Votre commentaire</h2>
            <textarea class="form-control" rows="3" readonly>{{billDetail.commentaire}}</textarea>
        </div>

        <h1 class="mt-5 d-flex flex-row">Votre facture &nbsp;<small>(n°{{billDetail.numeroFacture}})</small>
            <button (click)="downloadBill()" class="ml-auto btn btn-primary d-flex align-items-center">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                    <path
                        d="M160 152v8H32v-8h128zm-25.171-57.171l-5.658-5.658L100 118.343V32h-8v86.343L62.829 89.171 57.17 94.83 96 133.657l38.829-38.828z" />
                </svg>
                Télécharger la facture</button>
        </h1>

        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" [autoresize]="true"></pdf-viewer>
    </div>

    <ng-template #loading>
        <div class="bill-detail__loader loader d-flex flex-row justify-content-center">
            <div>
                <span class="loader__title">Chargement de la facture...</span>
                <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
            </div>
        </div>
    </ng-template>
</div>