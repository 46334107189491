<div class="mat-elevation-z8 declaration-history">
  <div class="d-flex flex-column flex-sm-row">
    <h1>Mes déclarations</h1>

    <ng-container *ngIf="hasPriceScale$ | async">
      <!-- To show the button -->
      <ng-container *ngIf="canUploadBill$ | async">
        <ng-container *ngIf="!showSelection && !isLoading">
          <!-- <button *ngIf="showSelectButton" (click)="selectDeclarationsForBill()"> -->
          <button (click)="selectDeclarationsForBill()" class="declaration-history__bill-button flex-row ml-auto">
            <svg class="declaration-history__icon declaration-history__icon--cash-frame"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
              <path
                d="M60 84.953h9.252c2.177-10.232 4.789-15.674 10.013-21.116C84.49 58.612 91.02 56 98.748 56c7.401 0 13.388 2.504 17.633 7.184 3.047 3.374 4.79 6.748 7.183 13.714l-7.945 2.177c-4.027-11.537-8.38-15.565-17.197-15.565-7.184 0-12.626 3.157-16.218 9.47-1.85 3.265-2.83 6.204-3.918 11.973h24.163v6.857H77.633c-.109 1.305-.109 2.394-.109 4.353 0 2.286 0 3.483.109 4.572h24.816v6.857H78.395c2.938 14.476 9.142 20.898 20.245 20.898 9.36 0 14.367-4.572 18.176-16.436l7.946 2.721c-2.395 7.184-4.354 10.667-8.055 14.368-4.68 4.462-10.884 6.857-18.067 6.857-8.381 0-15.239-2.94-20.463-8.816-4.68-5.225-6.857-10.014-8.925-19.592H60v-6.857h8.49a42.386 42.386 0 0 1-.218-4.354l.109-4.571H60v-6.857zM96 176c-44.112 0-80-35.888-80-80s35.888-80 80-80 80 35.888 80 80-35.888 80-80 80zm0-152c-39.701 0-72 32.299-72 72s32.299 72 72 72 72-32.299 72-72-32.299-72-72-72z" />
            </svg>
            <span>Préparer mes idemnités de mise en service</span>
          </button>
        </ng-container>
        <ng-container *ngIf="showSelection && !isLoadingBillable">
          <div class="declaration-history__bill-content d-flex flex-row ml-auto">
            <p *ngIf="!!dataSource.data && dataSource.data.length > 4" class="declaration-history__bill-guideline">
              Sélectionnez les déclarations de
              <b>mise en service</b> auxquelles vous souhaitez lier votre facture.
            </p>
            <p *ngIf="!!dataSource.data && dataSource.data.length < 1" class="text-warning"><b>Vous n'avez aucune
                déclaration
                pouvant être facturée.</b></p>
            <p *ngIf="!!dataSource.data && dataSource.data.length > 0 && dataSource.data.length < 5"
              class="text-warning">
              Vous devez avoir fait au
              moins <b>5 déclarations</b> de mise en service pour pouvoir les facturer.</p>
            <svg (click)="closeDeclarationsForBill()" class="declaration-history__icon declaration-history__icon--close"
              xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
              <path
                d="M101.657 96l57.172 57.171-5.658 5.658L96 101.657 38.829 158.83l-5.658-5.658L90.343 96 33.17 38.829l5.658-5.658L96 90.343l57.171-57.172 5.658 5.658L101.657 96z" />
            </svg>
          </div>
        </ng-container>
      </ng-container>

      <!--If stabe not registered in the club... -->
      <ng-container *ngIf="inviteStabeToRegisterClub$ | async">
        <div class="declaration-history__bill-content d-flex flex-row ml-auto">
          <p class="declaration-history__bill-guideline">Inscrivez-vous au <a [href]="(clubUrl$ | async)"><b>Club Degré
                +</b></a> et vous aurez la possibilité de demander vos indemnités de mise en service sur cette même page
            !
          </p>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <table [hidden]="isLoading || isLoadingBillable" [dataSource]="dataSource" mat-table class="full-width-table" matSort
    aria-label="Elements">

    <!-- Name Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date de déclaration</th>
      <!-- <td mat-cell *matCellDef="let row">{{row.date | date:'dd/MM/yyyy'}}</td> -->
      <td mat-cell *matCellDef="let row">{{row.dateDeclaration | date:'dd/MM/yyyy'}}</td>
      <!-- <td mat-cell *matCellDef="let row" [innerHtml]="transformDate(row.date)"></td> -->

    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
      <td mat-cell *matCellDef="let row">{{row.type}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="qui">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-lg-table-cell">Qui ?</th>
      <td mat-cell *matCellDef="let row" class="d-none d-lg-table-cell">{{row.qui}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="numeroSerie">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro de série</th>
      <td mat-cell *matCellDef="let row">{{row.numeroSerie}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="produit">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Produit</th>
      <td mat-cell *matCellDef="let row">{{row.produit}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="marque">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-lg-table-cell">Marque</th>
      <td mat-cell *matCellDef="let row" class="d-none d-lg-table-cell">{{row.marque}}</td>
    </ng-container>
    <!-- Name Column -->
    <ng-container matColumnDef="ville">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-lg-table-cell">Ville</th>
      <td mat-cell *matCellDef="let row" class="d-none d-lg-table-cell">{{row.ville}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="departement">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="d-none d-lg-table-cell">Département</th>
      <td mat-cell *matCellDef="let row" class="d-none d-lg-table-cell">{{row.departement}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="statut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
      <td mat-cell *matCellDef="let row">
        <span
          *ngIf="row.statut != 'Facture refusée' && row.statut != 'Facturée' && row.statut != 'Facture validée'">{{row.statut}}</span>
        <div *ngIf="row.statut == 'Facture refusée' || row.statut == 'Facturée' || row.statut == 'Facture validée'"
          [routerLink]="['bill', row.idFacture]" class="d-flex flex-row statut-refusee">
          <span>{{row.statut}}</span>
          <svg class="declaration-history__icon declaration-history__icon--question-frame ml-1"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
              d="M90.349 105.932c0-8.121 2.304-12.072 10.535-17.888 6.364-4.39 8.45-7.682 8.45-13.059 0-6.804-5.049-11.413-12.62-11.413-8.341 0-13.389 4.39-15.035 13.279l-8.45-1.098c1.097-6.474 2.963-10.315 7.024-13.937C84.533 57.976 90.349 56 97.043 56c12.948 0 21.728 7.572 21.728 18.766 0 5.157-1.646 9.437-4.829 12.949-1.975 2.085-3.511 3.292-8.45 6.584-5.816 3.84-7.133 6.255-7.133 12.84v4.718h-8.01v-5.925zm10.864 23.045c0 3.84-3.183 7.023-7.024 7.023-3.95 0-7.023-3.073-7.023-7.023 0-3.95 3.073-7.024 7.023-7.024 3.841 0 7.024 3.183 7.024 7.024zM96 176c-44.112 0-80-35.888-80-80s35.888-80 80-80 80 35.888 80 80-35.888 80-80 80zm0-152c-39.701 0-72 32.299-72 72s32.299 72 72 72 72-32.299 72-72-32.299-72-72-72z" />
          </svg>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="points">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Points</th>
      <td mat-cell *matCellDef="let row" class="text-center">{{row.points}}</td>
    </ng-container>

    <ng-container matColumnDef="select">
      <th [hidden]="!showSelection" mat-header-cell *matHeaderCellDef>
        <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td [hidden]="!showSelection" mat-cell *matCellDef="let row">
        <mat-checkbox color="primary" (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"
          *ngIf="isSelectableForBill(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <div *ngIf="isLoading" class="declaration-history__loader loader d-flex flex-row justify-content-center">
    <div>
      <span class="loader__title">Chargement de l'historique des déclarations...</span>
      <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
    </div>
  </div>

  <div *ngIf="isLoadingBillable" class="declaration-history__loader loader d-flex flex-row justify-content-center">
    <div>
      <span class="loader__title">Récupération des déclarations pouvant être facturées...</span>
      <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
    </div>
  </div>

  <mat-paginator *ngIf="!showSelection" [hidden]="isLoading" #paginator (page)="handlePageEvent($event)"
    [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize">
  </mat-paginator>

  <div class="d-flex flex-row">
    <button *ngIf="showSelection && !isLoadingBillable" (click)="prepareBill()"
      [disabled]="selection.selected.length < 5"
      class="declaration-history__bill-button btn ml-auto mt-2">Continuer</button>
  </div>
</div>

<!-- Modal to display an information -->
<ng-template #content let-modal>
  <div class="modal-header">
    <div class="modal-title">
      <span>
        {{modalTitle}}
      </span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{modalContent}}</p>
  </div>
</ng-template>