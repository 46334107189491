import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EventTypes, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PermissionService } from './core/services/permission.service';
import { StorageService } from './core/services/storage.service';


const RETURNURL_KEY = 'returnurl';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
// export class AppComponent {
//   title:string;
//   constructor(private titleService: Title){
//     this.titleService.setTitle('elmLeCube.fr - Déclaration Appareil')
//   }
// }
export class AppComponent implements OnInit {

  title = 'app';
  private storage: Storage;

  constructor(private eventService: PublicEventsService, private permissionService: PermissionService,
    private oidcSecurityService: OidcSecurityService, private router: Router, private storageService: StorageService) {
    this.storage = this.storageService.get();
  }

  ngOnInit(): void {

    // This subscription is done in order to log the permissions in the web console
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.UserDataChanged),
        //tap((notification) => console.log('user: ' + notification)),
        switchMap((notification) => this.permissionService.handlePermissions(notification.value != null))
      )
      .subscribe((perm) => console.log('permissions: ' + perm));

    // With this subscription, we check if we are authenticated
    // If we are, we navigate to the stored endpoint  
    // When the subscription is completed, we remove the return url from storage 
    this.oidcSecurityService
      .checkAuth()
      .subscribe(
        (isAuthenticated) => {
          if (isAuthenticated) {
            this.navigateToStoredEndpoint();
          }
        },
        (error) => console.log(error),
        () => this.storage.removeItem(RETURNURL_KEY),
      );

      // Observer on isAuthenticated
      // this.oidcSecurityService.isAuthenticated$.subscribe(
      //   value => alert("Alert isAuthenticated$ : " + value)
      // );
  }

  // Gets the current return url from storage and navigate to it
  // If this url is null or if it is equal to the current url, we do nothing
  // If the return url in the path of the unauthorized page, we navigate to home
  private navigateToStoredEndpoint() {
    const path = this.storage.getItem(RETURNURL_KEY);

    if (path == null || this.router.url === path) {
      return;
    }

    if (path.toString().includes('/unauthorized')) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate([path]);
    }
  }
}

