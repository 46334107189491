import { Observable } from 'rxjs';
import { Document } from './../models/document';
import { PriceScale } from './../models/price-scale';
import { SentBillResponse } from './../models/sent-bill-response';
import { DeclarationHistoryItem } from './../models/declaration-history-item';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Form } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class BillService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  sendBill(fd: FormData) {
    return this.http.post<SentBillResponse>(this.baseUrl + 'api/bill/sendBill', fd).pipe();
  }

  getPriceScale() {
    return this.http.get<PriceScale>(this.baseUrl + 'api/bill/priceScale').pipe();
  }

  getDocument(idDeclaration: number) {
    return this.http.get<Document>(this.baseUrl + 'api/bill/document?idDeclaration=' + idDeclaration);
  }

  hasExistingPriceScale(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl+'api/bill/hasExistingPriceScale');
  }
}
