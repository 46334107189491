<div [class.disabled]="isSubmitting" class="container-fluid declaration">

    <div class="declaration__header d-flex flex-row">
        <svg routerLink="/declaration" class="declaration__icon declaration__icon--back-menu"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
            <path
                d="M176 96c.002 13.242-5.379 25.268-14.059 33.941-8.673 8.68-20.699 14.06-33.941 14.059H56v-8h72c11.059-.002 21.033-4.47 28.284-11.716C163.53 117.034 167.998 107.058 168 96c-.002-11.059-4.47-21.033-11.716-28.284C149.034 60.47 139.06 56.002 128 56H34.657l21.171 21.172-5.656 5.656L19.343 52l30.829-30.828 5.656 5.656L34.657 48H128c13.242-.002 25.268 5.379 33.941 14.059C170.621 70.732 176.002 82.758 176 96z" />
        </svg>
        <div class="declaration__title">
            <h1 class="">Nouvelle déclaration de mise en service</h1>
            <p>Déclarer la mise en service d'un matériel Bosch ou elm.leblanc.</p>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" #commissioningDeclarationForm="ngForm" class="declaration__form form col-xl-10">

        <div *ngIf="hasSubmitError" class="alert alert-danger mt-2">
            Une erreur interne est survenue. La déclaration n'a pas pu être envoyée. <br><span
                style="font-style: italic;">Erreur {{errorStatus}}, ({{errorStatusText}}</span>)
        </div>
        <div *ngIf="hasPostSubmitError" class="alert alert-danger mt-2">
            {{errorStatusText}}
        </div>

        <div class="form__section">
            <h2>Informations mise en service</h2>
            <div class="form-group row form__item">
                <label for="numeroSerie" class="col-sm-4 col-form-label">Numéro de série*</label>
                <div class="col-sm-8">
                    <div class="d-flex flex-column flex-lg-row align-items-center">
                        <input required pattern="^[A-Z\d]{4}-[A-Z\d]{3}-[A-Z\d]{6}-[A-Z\d]{10}" maxlength="26"
                            [(ngModel)]="declaration.numeroSerie" name="numeroSerie" #numeroSerie="ngModel"
                            (ngModelChange)="updateSerialNumber(numeroSerie.valid)" id="numeroSerie" type="text"
                            class="form-control" placeholder="XXXX-XXX-XXXXXX-XXXXXXXXXX">
                        <app-scan-modal class="ml-auto mt-2 mt-lg-0" (serialNumber)="setSerialNumberFromScan($event)">
                        </app-scan-modal>
                    </div>
                    <div [hidden]="numeroSerie.valid || numeroSerie.pristine" class="alert alert-danger">
                        <div *ngIf="numeroSerie.errors?.required">Le numéro de série de l'appareil est requis.</div>
                        <div *ngIf="numeroSerie.errors?.pattern">Le numéro de
                            série saisie n'a pas le bon format.</div>
                    </div>
                    <ng-container *ngIf="numeroSerie.valid">
                        <div *ngIf="deviceInfo$ | async as deviceInfo; else loadingDeviceInfo" class="mt-2">
                            <ng-container *ngIf="deviceInfo.isValid; else notValid">
                                <p>Marque : {{deviceInfo.brand}}</p>
                                <p>Modèle : {{deviceInfo.deviceName}}</p>
                                <p *ngIf="deviceInfo.points > 0">Points Degré+ : {{deviceInfo.points}}</p>
                            </ng-container>
                            <ng-template #notValid>
                                <p class="text-danger">Ce numéro de série ne correspond à aucun appareil connu.</p>
                            </ng-template>
                        </div>
                        <ng-template #loadingDeviceInfo>
                            <div class="spinner-border mt-2" role="status">
                                <span class="sr-only">Chargement...</span>
                            </div>
                        </ng-template>
                    </ng-container>
                </div>
            </div>
            <!-- <div class="form-row mt-2 mb-3">
                <span>Ajouter un numéro de plaque de robinetterie : &nbsp;&nbsp;&nbsp;&nbsp;</span>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="1"
                        (change)="onDisplay()" [checked]="isSelected">
                    <label class="form-check-label" for="inlineRadio1">Oui</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="2"
                        (change)="onDisplayFalse()" checked>
                    <label class="form-check-label" for="inlineRadio2">Non</label>
                </div>
            </div>
            <div *ngIf="isDisplay" class="form-group row form__item">
                <label for="numeroPlaqueRobinet" class="col-sm-4 col-form-label">Numéro plaque de robinetterie</label>
                <div class="col-sm-8">
                    <input pattern="^[A-Z\d]{4}-[A-Z\d]{3}-[A-Z\d]{6}-[A-Z\d]{10}" maxlength="26"
                        [(ngModel)]="declaration.numeroPlaqueRobinet" name="numeroPlaqueRobinet"
                        #numeroPlaqueRobinet="ngModel" (ngModelChange)="updateSerialNumber()" id="numeroPlaqueRobinet"
                        type="text" class="form-control" placeholder="XXXX-XXX-XXXXXX-XXXXXXXXXX">
                    <div [hidden]="numeroPlaqueRobinet.valid || numeroPlaqueRobinet.pristine"
                        class="alert alert-danger">
                        <div *ngIf="numeroPlaqueRobinet.errors?.required">Le numéro de série de l'appareil est requis.
                        </div>
                        <div *ngIf="numeroPlaqueRobinet.errors?.pattern">Le numéro de
                            série saisie n'a pas le bon format.</div>
                    </div>
                </div>
            </div> -->
            <div class="form-group row form__item">
                <label for="dateMiseEnService" class="col-sm-4 col-form-label">Date de mise en service*</label>
                <div class="col-sm-8">
                    <input required [max]="today | date:'yyyy-MM-dd'"
                        [ngModel]="declaration.dateMiseEnService | date:'yyyy-MM-dd'"
                        (ngModelChange)="declaration.dateMiseEnService = $event" name="dateMiseEnService"
                        #dateMiseEnService="ngModel" id="dateMiseEnService" type="date" class="form-control">
                    <div [hidden]="dateMiseEnService.valid || dateMiseEnService.pristine" class="alert alert-danger">
                        La date de mise en service de l'appareil est requise.
                    </div>
                </div>
            </div>
        </div>
        <div class="form__section">
            <h2>Lieu de mise en service</h2>
            <div class="form-group row form__item">
                <label for="address" class="col-sm-4 col-form-label">Adresse*</label>
                <div class="col-sm-8">
                    <input required [(ngModel)]="declaration.adresseMiseEnService" name="adresseMiseEnService"
                        #adresseMiseEnService="ngModel" id="address" type="text" class="form-control">
                    <div [hidden]="adresseMiseEnService.valid || adresseMiseEnService.pristine"
                        class="alert alert-danger">
                        L'adresse de mise en service est requise.
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="ville" class="col-sm-4 col-form-label">Ville*</label>
                <div class="col-sm-8">
                    <input required [(ngModel)]="declaration.villeMiseEnService" name="villeMiseEnService"
                        #villeMiseEnService="ngModel" id="ville" type="text" class="form-control">
                    <div [hidden]="villeMiseEnService.valid || villeMiseEnService.pristine" class="alert alert-danger">
                        La ville de la mise en service est requise.
                    </div>
                </div>
            </div>
            <div class="form-group row form__item">
                <label for="codePostal" class="col-sm-4 col-form-label">Code postal*</label>
                <div class="col-sm-8">
                    <input required pattern="[0-9]{5}" maxlength="5" [(ngModel)]="declaration.codePostalMiseEnService"
                        name="codePostalMiseEnService" #codePostalMiseEnService="ngModel" id="codePostal" type="text"
                        class="form-control">
                    <div [hidden]="codePostalMiseEnService.valid || codePostalMiseEnService.pristine"
                        class="alert alert-danger">
                        <div *ngIf="codePostalMiseEnService.errors?.required">
                            Le code postal d'MiseEnService est requis.
                        </div>
                        <div *ngIf="codePostalMiseEnService.errors?.pattern">
                            Le code doit compter 5 chiffres.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form__section">
            <h2>Document prouvant la mise en service</h2>
            <div class="form-group row form__item">
                <label class="col-sm-4 col-form-label">Mettez-en ligne un document/photo attestant
                    que vous avez mis en service
                    l'appareil :</label>
                <div class="col-sm-8">
                    <!-- <input required accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" type="file"
                        class="form-control-file" id="documentFile" (change)="onFileSelected($event)"> -->
                    <input required accept=".jpg,.jpeg,.png,.doc,.docx,.pdf" (change)="onFileSelected($event)"
                        id="document" type="file" class="form-control-file" hidden>
                    <label for="document">
                        <div class="document__upload d-flex flex-row">
                            <svg class="document__icon document__icon--document-arrow-up"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                                <path
                                    d="M120 80H56v-8h64v8zm0 12H56v8h64v-8zm-64 28h40v-8H56v8zm120 24c0 17.645-14.355 32-32 32s-32-14.355-32-32 14.355-32 32-32 32 14.355 32 32zm-8 0c0-13.234-10.766-24-24-24s-24 10.766-24 24 10.766 24 24 24 24-10.766 24-24zm-38.829-2.829l5.658 5.658 5.171-5.172V160h8v-18.343l5.171 5.172 5.658-5.658L144 126.343l-14.829 14.828zM120 175.978V176H40V16h81.815L152 50.497v54.308a40.027 40.027 0 0 0-8-.805V56h-28V24H48v144h64.022a40.342 40.342 0 0 0 7.978 7.978zM124 48h15.185L124 30.646V48z" />
                            </svg>
                            <span>Joindre un document</span>
                        </div>
                    </label>
                    <div class="declaration__file-validation">
                        <div *ngIf="isValidFile()" class="declaration__file-name">
                            Fichier choisi : {{selectedFile.name}}
                        </div>
                        <div *ngIf="selectedFile != null && !isValidFile()" class="alert alert-danger">
                            Le fichier que vous nous envoyez doit avoir l'un des formats suivants : .jpeg, .jpg, .png,
                            .pdf, .doc, .docx
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span class="form__note">Les champs marqués d'un astérisque (*) sont obligatoires.</span>
        <button [disabled]="!commissioningDeclarationForm.form.valid || isSubmitting ||!isValidFile()"
            class="d-block ml-auto col-4 col-xl-2 form__button">Enregistrer</button>
    </form>
</div>

<div *ngIf="isSubmitting" class="loading">
    <span class="loading__title">Envoi de la déclaration...</span>
    <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
</div>