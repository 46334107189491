import { Document } from "./document";

export abstract class Utility {

    public static _base64ToArrayBuffer(base64) {
      var binary_string = base64.replace(/\\n/g, '');
      binary_string = window.atob(base64);
      var len = binary_string.length;
      var bytes = new Uint8Array(len);
      for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
    }

    public static convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    public static downloadFile(documentFile: Document) {

        const blobData = this.convertBase64ToBlobData(documentFile.data.toString());

        // if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        //     window.navigator.msSaveOrOpenBlob(blobData, documentFile.name);
        // } else { // chrome
        //     const blob = new Blob([blobData], { type: documentFile.format });
        //     const url = window.URL.createObjectURL(blob);
        //     // window.open(url);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.download = documentFile.name;
        //     link.click();
        // }


        // chrome
        const blob = new Blob([blobData], { type: documentFile.format });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = documentFile.name;
        link.click();
    }

    public static downloadBill(data: string, name: string) {

        const blobData = this.convertBase64ToBlobData(data.toString());

        // if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
        //     window.navigator.msSaveOrOpenBlob(blobData, name);
        // } else { // chrome
        //     const blob = new Blob([blobData], { type: 'application/pdf' });
        //     const url = window.URL.createObjectURL(blob);
        //     // window.open(url);
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.download = name+'.pdf';
        //     link.click();
        // }

        // chrome
        const blob = new Blob([blobData], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        // window.open(url);
        const link = document.createElement('a');
        link.href = url;
        link.download = name+'.pdf';
        link.click();
    }
}
