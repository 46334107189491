import { StorageService } from './../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { ApplicationPaths, ReturnUrlType } from '../../api-authorization.constants';

const RETURNURL_KEY = 'returnurl';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  storage: Storage;

  constructor(private oidcSecurityService: OidcSecurityService, private activatedRoute: ActivatedRoute, private storageService: StorageService) {
    this.storage = this.storageService.get();
  }

  ngOnInit(): void {
    this.storage.setItem(RETURNURL_KEY, this.getReturnUrl());
    this.oidcSecurityService.authorize(null, { customParams: { style_id: 'tt_elm' } });
  }

  private getReturnUrl(state?: INavigationState): string {
    const fromQuery = (this.activatedRoute.snapshot.queryParams as INavigationState).returnUrl;
    // If the url is comming from the query string, check that is either
    // a relative url or an absolute url
    if (fromQuery &&
      !(fromQuery.startsWith(`${window.location.origin}/`) ||
        /\/[^\/].*/.test(fromQuery))) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl) ||
      fromQuery ||
      ApplicationPaths.DefaultLoginRedirectPath;
  }

}

interface INavigationState {
  [ReturnUrlType]: string;
}
