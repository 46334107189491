<div class="px-2 px-lg-5">
    <table class="table bg-light">
        <tr>
            <th scope="col">Date de mise en service</th>
            <th scope="col">Numéro de série</th>
            <th scope="col">Produit</th>
            <th scope="col">Marque</th>
            <th scope="col"></th>
        </tr>
        <tr *ngFor="let declaration of getDeclarationsFromProductType(); let i = index">
            <td>{{declaration.date | date:'shortDate'}}</td>
            <td>{{declaration.numeroSerie}}</td>
            <td>{{declaration.produit}}</td>
            <td>{{declaration.marque}}</td>
            <td>
                <ng-container *ngIf="declaration.hasDocument">
                    <button *ngIf="!isDownlaoding[i]; else downloading" (click)="downloadDocument(declaration, i)"
                        class="btn btn-sm btn-primary d-flex align-items-center">
                        <svg class="me-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                            <path
                                d="M160 152v8H32v-8h128zm-25.171-57.171l-5.658-5.658L100 118.343V32h-8v86.343L62.829 89.171 57.17 94.83 96 133.657l38.829-38.828z" />
                        </svg>
                        Télécharger le document associé</button>
                    <ng-template #downloading>
                        <button class="btn btn-sm btn-primary" type="button" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Téléchargement...
                        </button>
                    </ng-template>
                </ng-container>
            </td>
        </tr>
    </table>
</div>