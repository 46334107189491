import { tap, switchMap } from 'rxjs/operators';
import { BillManagementService } from './../../services/bill-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BillDetail } from './../../models/bill-detail';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Utility } from 'src/app/models/utility';

@Component({
  selector: 'app-my-bill',
  templateUrl: './my-bill.component.html',
  styleUrls: ['./my-bill.component.scss']
})
export class MyBillComponent implements OnInit {

  billId: number;
  pdfSrc: ArrayBufferLike;

  billDetail$: Observable<BillDetail>;
  billDetail: BillDetail;

  constructor(private route: ActivatedRoute, private router: Router, private billManagementService: BillManagementService) { }

  ngOnInit(): void {
    this.billDetail$ = this.route.paramMap.pipe(
      tap(params => {
        this.billId = +params.get("billId");
      }),
      switchMap(_ => this.billManagementService.getBillDetail(this.billId).pipe(
        tap(data => {
          this.billDetail = data;
          
          this.pdfSrc = Utility._base64ToArrayBuffer(data.fichier);
        })
      ))
    )
  }

  downloadBill() {
    Utility.downloadBill(this.billDetail.fichier, `Facture n°${this.billDetail.numeroFacture} - Société : ${this.billDetail.societe}`);
  }
}
