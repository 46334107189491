<div class="container-fluid choose-declaration">

    <div class="d-flex flex-row">
        <div class="choose-declaration__content">
            <h1 class="">Déclaration en ligne</h1>
            <p>En faisant une déclaration en ligne, vous nous apportez des informations précieuses sur le cycle de vie
                de nos produits après fabrication.</p>
            <div class="d-flex flex-column flex-sm-row">
                <div routerLink="installation-declaration" id="installationDeclaration"
                    class="d-flex flex-row choose-declaration__button">
                    <svg class="choose-declaration__icon choose-declaration__icon--edit"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                        <path
                            d="M16 148h40v8H16v-8zm154.85-70.65l-2.829 2.828-.021-.021-65.955 65.955-39.543 9.886 9.886-39.543 68.763-68.805c8.187-8.187 21.51-8.187 29.698 0 8.188 8.189 8.188 21.511 0 29.7zM100 136.842l54.35-54.35-18.301-18.385L81.657 118.5 100 136.843zm-26.502 8.16l18.678-4.67-14.009-14.009-4.67 18.678zm91.694-91.696c-4.858-4.859-12.638-5.06-17.74-.603l-5.747 5.747 18.301 18.386 5.79-5.79c4.456-5.1 4.255-12.88-.604-17.74z" />
                    </svg>
                    <span>Déclarer une <br>installation</span>
                </div>
                <div routerLink="commissioning-declaration" id="commissioningDeclaration"
                    class="d-flex flex-row choose-declaration__button">
                    <svg class="choose-declaration__icon choose-declaration__icon--edit"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                        <path
                            d="M16 148h40v8H16v-8zm154.85-70.65l-2.829 2.828-.021-.021-65.955 65.955-39.543 9.886 9.886-39.543 68.763-68.805c8.187-8.187 21.51-8.187 29.698 0 8.188 8.189 8.188 21.511 0 29.7zM100 136.842l54.35-54.35-18.301-18.385L81.657 118.5 100 136.843zm-26.502 8.16l18.678-4.67-14.009-14.009-4.67 18.678zm91.694-91.696c-4.858-4.859-12.638-5.06-17.74-.603l-5.747 5.747 18.301 18.386 5.79-5.79c4.456-5.1 4.255-12.88-.604-17.74z" />
                    </svg>
                    <span>Déclarer une <br>mise en service</span>
                </div>
            </div>
            <div class="d-flex mt-5">
                <a *ngxPermissionsOnly="['ChefEntreprise', 'Employe']" [href]="(clubDegrePlusUrl$ | async)"
                    class="d-flex flex-row align-items-center choose-declaration__backToClub">
                    <span>Retour au Club Degré+</span>
                    <img src="../../assets/images/LogoClubDegrePlus.png" alt="">
                </a>
            </div>
        </div>
        <div class="flex-fill d-none d-xl-flex flex-row choose-declaration__background">
            <img class="choose-declaration__icon choose-declaration__icon--document-add ml-auto"
                src="../../assets/images/document-add.png">
        </div>
    </div>
</div>