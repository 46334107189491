<div class="container-fluid succeeded-declaration">

    <div class="d-flex flex-row">
        <div class="succeeded-declaration__content">

            <div [ngSwitch]="info.declarationType">
                <div *ngSwitchCase="'installation'">
                    <h1 class="">Nouvelle déclaration d'installation</h1>
                    <p>Déclarer l'installation d'un matériel Bosch ou elm.leblanc</p>
                </div>
                <div *ngSwitchCase="'commissioning'">
                    <h1 class="">Nouvelle déclaration de mise en service</h1>
                    <p>Déclarer la mise en service d'un matériel Bosch ou elm.leblanc</p>
                </div>
                <div *ngSwitchDefault>
                    <h1 class="">Nouvelle déclaration d'appareil</h1>
                    <p>Déclarer un matériel Bosch ou elm.leblanc</p>
                </div>
            </div>

            <div class="succeeded-declaration__success d-flex flex-row">
                <svg class="succeeded-declaration__icon succeeded-declaration__icon--checkmark"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                    <path d="M76 141.657L33.171 98.829l5.658-5.658L76 130.343l77.171-77.172 5.658 5.658L76 141.657z" />
                </svg>
                <span>Enregistrement réussi</span>
            </div>

            <div *ngIf="info.hasDuplicateSerialNumberAnomaly" class="succeeded-declaration__anomaly d-flex flex-row">
                <svg class="succeeded-declaration__icon succeeded-declaration__icon--alert-warning"
                    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                    <path
                        d="M175.783 160H16.217L96 15.735 175.783 160zm-146-8h132.434L96 32.265 29.783 152zm73.328-15.111c0 3.89-3.222 7.111-7.111 7.111-4 0-7.111-3.11-7.111-7.111 0-4 3.11-7.111 7.111-7.111 3.889 0 7.111 3.222 7.111 7.11zm-12.223-48V64h10.223v24.889l-2.667 30.667h-4.888l-2.668-30.667z"/></svg>

                <span>L'installation d'un appareil avec un numéro de série identique a déjà été déclarée. Nous n'avons
                    donc pas pu attribuer vos points sur le Club Degré +.<br>Veuillez nous excuser pour la gêne
                    occasionnée, l'incident est en cours de résolution.</span>
            </div>

            <div class="d-flex flex-column flex-sm-row">
                <div routerLink="/declaration/installation-declaration" id="installationDeclaration"
                    class="d-flex flex-row succeeded-declaration__button">
                    <svg class="succeeded-declaration__icon succeeded-declaration__icon--edit"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                        <path
                            d="M16 148h40v8H16v-8zm154.85-70.65l-2.829 2.828-.021-.021-65.955 65.955-39.543 9.886 9.886-39.543 68.763-68.805c8.187-8.187 21.51-8.187 29.698 0 8.188 8.189 8.188 21.511 0 29.7zM100 136.842l54.35-54.35-18.301-18.385L81.657 118.5 100 136.843zm-26.502 8.16l18.678-4.67-14.009-14.009-4.67 18.678zm91.694-91.696c-4.858-4.859-12.638-5.06-17.74-.603l-5.747 5.747 18.301 18.386 5.79-5.79c4.456-5.1 4.255-12.88-.604-17.74z" />
                    </svg>
                    <span>Déclarer une <br>installation</span>
                </div>
                <div routerLink="/declaration/commissioning-declaration" id="commissioningDeclaration"
                    class="d-flex flex-row succeeded-declaration__button">
                    <svg class="succeeded-declaration__icon succeeded-declaration__icon--edit"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                        <path
                            d="M16 148h40v8H16v-8zm154.85-70.65l-2.829 2.828-.021-.021-65.955 65.955-39.543 9.886 9.886-39.543 68.763-68.805c8.187-8.187 21.51-8.187 29.698 0 8.188 8.189 8.188 21.511 0 29.7zM100 136.842l54.35-54.35-18.301-18.385L81.657 118.5 100 136.843zm-26.502 8.16l18.678-4.67-14.009-14.009-4.67 18.678zm91.694-91.696c-4.858-4.859-12.638-5.06-17.74-.603l-5.747 5.747 18.301 18.386 5.79-5.79c4.456-5.1 4.255-12.88-.604-17.74z" />
                    </svg>
                    <span>Déclarer une <br>mise en service</span>
                </div>
            </div>
            <div class="d-flex mt-5">
                <a *ngxPermissionsOnly="['ChefEntreprise', 'Employe']" [href]="(clubDegrePlusUrl$ | async)" class="d-flex flex-row align-items-center succeeded-declaration__backToClub">
                    <span>Retour au Club Degré+</span>
                    <img src="../../assets/images/LogoClubDegrePlus.png" alt="">
                </a>    
            </div>
        </div>
        <div class="flex-fill d-none d-xl-flex flex-row succeeded-declaration__background">
            <img class="succeeded-declaration__icon succeeded-declaration__icon--document-add ml-auto"
                src="../../assets/images/document-check.png">
        </div>
    </div>
</div>