<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Scannez {{whatToScan}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <zxing-scanner [previewFitMode]="'fill'" [enable]="true" [formats]="['DATA_MATRIX']" [torch]="isFlashlightOn"
            (scanSuccess)="scanSuccessHandler($event, modal)"></zxing-scanner>
    </div>
</ng-template>

<button type="button" (click)="open(content)"
    class="btn py-1 px-3 d-flex flex-row align-items-center declaration__scan">
    <svg class="mr-1 declaration__icon declaration__icon--dmc-code" version="1.1" id="Icon_x5F_contour"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        viewBox="0 0 192 192" xml:space="preserve">
        <path d="M40,24h-8v-8h8V24z M120,160h8v-8h16v-8h-24v8V160z M160,160h8v8h8v8H24h-8v-8V16h8v24h8v24h8v16h8h8v-8h8v8h8v8h8v-8h8v-8
h8v8v8h-8v24h16v-8h8v-8h8v8h8v-8h16v8h-8v8h8v8h-8v8v8h-16v8h-8v8h-8v16h24v-8h8v8h24V160z M48,96h16v-8H48V96z M24,96h8v-8h-8V96z
M32,168v-8h-8v8H32z M88,168v-8h8v-8h-8v-8h8v-8h-8v-8h40v-8H88v8h-8v-8h-8v-8h-8v8H48h-8v-8h-8v-8h-8v24h8v-8h8v8h16v8h-8v8h-8v-8
H24v8h8v8h8v16h16v-8h-8v-16h8h8v24h8v-16h8v16H88z M176,144v-8h-8v8H176z M176,160v-8h-8v8H176z M152,152v8h8v-8h8v-8h-16V152z
M72,144h16v-8H72V144z M72,16h-8v8h8V16z M64,32v8h8h8v-8h8h8v16h8v-8h8v-8h-8V16h-8v8h-8v-8h-8v8h-8v8H64z M56,56V40h-8v24h8h8v-8
H56z M152,80v-8h-16v8H152z M152,80v8h16v-8h8v-8h-16v8H152z M152,104h8v8h16v-8h-8v-8h-16V104z M112,80h-8v8h8h8V72h-8V80z
M152,128h-8v8h8h8h8v-8h8v-8h-16v-8h-8V128z M40,32v8h8v-8H40z M104,56h8v-8h-8V56z M152,72h8v-8h-8V72z M176,96v-8h-8v8H176z
M120,40h-8v8h8v16h8v8h8v-8h8v-8h8v-8h-8h-8V32V16h-8v8h-8v-8h-8v16h8V40z M64,32v-8h-8v-8h-8v16h8H64z M80,72V56h8V40h-8v8h-8v16
h-8v8h8H80z M168,32h8v-8h-8v-8h-8v8h-8v-8h-8v16h8v16h8v16h8h8v-8h-8v-8h8v-8h-8h-8v-8H168z" />
    </svg>
    <div class="ml-3">Scan du code barre</div>
</button>