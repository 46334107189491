import { startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UrlService } from '../../core/services/url.service';
import { SucceededDeclarationInfo } from '../../models/succeeded-declaration-info';
import { ActivatedRoute, Router } from '@angular/router';
import { PointsNotifierService } from '../../services/points-notifier.service';
import { Component, Input, OnInit } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'succeeded-declaration',
  templateUrl: './succeeded-declaration.component.html',
  styleUrls: ['./succeeded-declaration.component.scss'],
  animations: [
    trigger('popOverState', [
      state('show', style({
        opacity: 1
      })),
      state('hide', style({
        opacity: 0
      })),
      transition('show => hide', animate('600ms ease-out')),
      transition('hide => show', animate('1000ms ease-in'))
    ])
  ]
})
export class SucceededDeclarationComponent implements OnInit {

  clubDegrePlusUrl$ : Observable<string>;

  info: SucceededDeclarationInfo = new SucceededDeclarationInfo(null, false, false, null, null);

  hasDuplicateSerialNumberAnomaly

  constructor(private router: Router, private route: ActivatedRoute, private pointsNotifierService: PointsNotifierService, private urlService: UrlService) {}

  ngOnInit(): void {

    if ('info' in history.state) {
      this.info = history.state.info;
    }

    if (this.info.showPointsNotification) {
      this.pointsNotifierService.showPointsNotification(this.info.pointsEarned.toString(), this.info.newBalance.toString());
    }

    this.clubDegrePlusUrl$ = this.urlService.getClubUrl().pipe(
      startWith('#'),
    );
  }
}