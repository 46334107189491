<div class="points-notification d-flex flex-row">
    <div>
        <img class="points-notification__logo" src="../../assets/images/degrePlus_logo.png">
    </div>
    <div class="points-notification__content d-flex flex-column justify-content-around">
        <div class="points-notification__description">
            Votre compte Degré + a été <b>crédité</b> de
        </div>
        <div class="points-notification__amount">
            + {{data.pointsEarned}} points
        </div>
        <div class="points-notification__new-balance new-balance d-flex flex-row justify-content-between">
            <span class="new-balance__title">
                Nouveau solde :
            </span>
            <span class="new-balance__amount">
                {{data.newBalance}} points
            </span>
        </div>
    </div>
</div>