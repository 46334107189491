import { tap, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PriceScale } from '../../models/price-scale';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DeclarationHistoryItem } from '../../models/declaration-history-item';
import { BillDetail } from '../../models/bill-detail';
import { BillManagementService } from '../../services/bill-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, Input, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { BillRecap } from '../../models/bill-recap';
import { Utility } from 'src/app/models/utility';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.scss']
})

export class BillDetailComponent implements OnInit {

  billId: number;
  pdfSrc: ArrayBufferLike;

  billDetail$: Observable<BillDetail>;
  billDetail: BillDetail;

  isSending: boolean = false;

  // For the modal
  closeResult = '';
  isValidation: boolean;
  reasonRefusal: string = null;

  // amounts for each device type (based on bill recap)
  billRecap: BillRecap[];
  amountGazC: number
  amountGazBT: number
  amountEauxChaudesC: number
  amountEauxChaudesBT: number


  constructor(private route: ActivatedRoute, private router: Router, private billManagementService: BillManagementService, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.billDetail$ = this.route.paramMap.pipe(
      tap(params => {
        this.billId = +params.get("billId");
      }),
      switchMap(_ => this.billManagementService.getBillDetail(this.billId).pipe(
        tap(data => {
          this.billDetail = data;

          this.pdfSrc = Utility._base64ToArrayBuffer(data.fichier);

          // initialize bill recap to display the amounts for each device type
          this.billRecap = this.createBillRecap(this.billDetail.declarations);

          this.amountGazC = this.billRecap.filter(x => x.productType == 'gaz condensation')[0]?.totalPrice ?? 0
          this.amountGazBT = this.billRecap.filter(x => x.productType == 'gaz basse température')[0]?.totalPrice ?? 0
          this.amountEauxChaudesC = this.billRecap.filter(x => x.productType == 'eaux chaudes condensation')[0]?.totalPrice ?? 0
          this.amountEauxChaudesBT = this.billRecap.filter(x => x.productType == 'eaux chaudes basse température')[0]?.totalPrice ?? 0
        })
      ))
    )
  }

  /** Function called when the bill is validated of refused */
  confirmValidationOrRefusal() {

    // Close modals
    this.modalService.dismissAll();

    this.isSending = true;

    const fd = new FormData();
    fd.append('billId', this.billId.toString());
    if (this.isValidation) {
      // add amounts data
      fd.append('amountGazC', this.amountGazC.toString());
      fd.append('amountGazBT', this.amountGazBT.toString());
      fd.append('amountEauxChaudesC', this.amountEauxChaudesC.toString());
      fd.append('amountEauxChaudesBT', this.amountEauxChaudesBT.toString());

      this.billManagementService.validateBill(fd).subscribe(
        data => {
          this.isSending = false;
          if (data.success) {
            this.showSuccessToastr("La facture a bien été validée.");
            setTimeout(() => {
              this.router.navigateByUrl('/bill-management');
            }, 1000);
          }
        }
      );
    } else {
      fd.append('reasonRefusal', this.reasonRefusal);
      this.billManagementService.refuseBill(fd).subscribe(
        data => {
          this.isSending = false;
          if (data.success) {
            this.showSuccessToastr("La facture a bien été refusée.");
            setTimeout(() => {
              this.router.navigateByUrl('/bill-management');
            }, 1000);
          }
        }
      );
    }
  }

  /** Modal functions */
  openBillModal(content, isValidation: boolean) {
    this.isValidation = isValidation;
    this.open(content);
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private showSuccessToastr(text: string) {
    this.toastr.success(text);
  }

  downloadBill() {
    Utility.downloadBill(this.billDetail.fichier, `Facture n°${this.billDetail.numeroFacture} - Société : ${this.billDetail.societe}`);
  }

  /** Create a BillRecap array in function of the commissioning declarations selected */
  private createBillRecap(declarations: DeclarationHistoryItem[]): BillRecap[] {

    let typesFamille = declarations.map(x => x.typeFamille);
    let distinctTypesFamille = [...new Set(typesFamille)];

    let billRecap: BillRecap[] = [];

    distinctTypesFamille.forEach(type => {
      let productType = type;
      let numberOfItems = declarations.filter(x => x.typeFamille == type).length;
      let unitPrice = this.getPriceFromType(type);
      let totalPrice = this.getAmountFromType(type) ?? unitPrice * numberOfItems;
      billRecap.push(new BillRecap(productType, numberOfItems, unitPrice, totalPrice));
    });

    return billRecap.sort(function (a, b) {
      return a.numberOfItems < b.numberOfItems || a.productType == 'autres' || b.productType == 'autres' ? 1 : -1;
    });
  }

  /** Get the price from the price scale in function of the family type */
  private getPriceFromType(typeFamille: string): number {
    if (typeFamille == "gaz basse température") {
      return this.billDetail.bareme.baremeGazBasseTemperature;
    } else if (typeFamille == "gaz condensation") {
      return this.billDetail.bareme.baremeGazCondensation;
    } else if (typeFamille == "eaux chaudes basse température") {
      return this.billDetail.bareme.baremeEauxChaudesBasseTemperature;
    } else if (typeFamille == "eaux chaudes condensation") {
      return this.billDetail.bareme.baremeEauxChaudesCondensation;
    } else if (typeFamille == "autres") {
      return 0;
    }
  }

  /** Get the amount from the price scale in function of the family type (if exists) */
  private getAmountFromType(typeFamille : string): number{
    if (typeFamille == "gaz basse température") {
      return this.billDetail.bareme.montantGazBasseTemperature;
    } else if (typeFamille == "gaz condensation") {
      return this.billDetail.bareme.montantGazCondensation;
    } else if (typeFamille == "eaux chaudes basse température") {
      return this.billDetail.bareme.montantEauxChaudesBasseTemperature;
    } else if (typeFamille == "eaux chaudes condensation") {
      return this.billDetail.bareme.montantEauxChaudesCondensation;
    } 
  }

  public getTotalNumberOfDevices() {
    if (this.billRecap.length < 1) {
      throw new Error('source contains no elements');
    }
    return this.billRecap.reduce((ty, bR) => ty + bR.numberOfItems, 0);
  }

  public getTotalPrice() {
    if (this.billRecap.length < 1) {
      throw new Error('source contains no elements');
    }
    return this.billRecap.reduce((ty, bR) => ty + bR.totalPrice, 0);
  }
}