import { Injectable } from '@angular/core';
import { ApplicationPaths } from 'src/app/core/api-authorization.constants';
import { LogLevel, OpenIdConfiguration, StsConfigHttpLoader } from 'angular-auth-oidc-client';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CustomStorage } from '../custom-storage';

@Injectable()
export class ConfigService {

  constructor(private httpClient: HttpClient) { }

  load() {
    const config$ = this.httpClient.get<any>(ApplicationPaths.ApiAuthorizationClientConfigurationUrl)
      .pipe(
        map((customConfig: any) => {
          const config = {
            configId: 'openIdConfig1',
            silentRenew: true,
            //useRefreshToken: true,
            //silentRenewUrl: customConfig.redirectUrl.replace(/\/$/, '') + '/silent-renew.html',
            //eagerLoadAuthWellKnownEndpoints: false,
            useRefreshToken: true,
            //storage: new CustomStorage(),
            historyCleanupOff: false,
            //tokenRefreshInSeconds: 10,
            maxIdTokenIatOffsetAllowedInSeconds: 7200,
            //authority: customConfig.stsServer,
            ...customConfig
          } as OpenIdConfiguration;
          if (customConfig.client_secret) {
            config.customParamsAuthRequest = {
              client_secret: customConfig.client_secret
            };
          }
          return config;
        })
    );
    return new StsConfigHttpLoader(config$);
  }
}
