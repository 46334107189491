import { PriceScale } from '../../models/price-scale';
import { DeclarationHistoryItem } from '../../models/declaration-history-item';
import { BillRecap } from '../../models/bill-recap';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-bill-recap-table',
  templateUrl: './bill-recap-table.component.html',
  styleUrls: ['./bill-recap-table.component.scss']
})
export class BillRecapTableComponent implements OnInit {

  @Input() declarations: DeclarationHistoryItem[];
  @Input() priceScale: PriceScale;
  @Input() isSticky: boolean;

  billRecap: BillRecap[]

  public isCollapsed = [true, true, true, true];

  constructor() { }

  ngOnInit(): void {
    this.billRecap = this.createBillRecap(this.declarations);
  }

  /** Create a BillRecap array in function of the commissioning declarations selected */
  private createBillRecap(declarations: DeclarationHistoryItem[]): BillRecap[] {

    let typesFamille = declarations.map(x => x.typeFamille);
    let distinctTypesFamille = [...new Set(typesFamille)];

    let billRecap: BillRecap[] = [];

    distinctTypesFamille.forEach(type => {
      let productType = type;
      let numberOfItems = declarations.filter(x => x.typeFamille == type).length;
      let unitPrice = this.getPriceFromType(type);
      let totalPrice = this.getAmountFromType(type) ?? unitPrice * numberOfItems;
      billRecap.push(new BillRecap(productType, numberOfItems, unitPrice, totalPrice));
    });

    return billRecap.sort(function (a, b) {
      return a.numberOfItems < b.numberOfItems || a.productType == 'autres' || b.productType == 'autres' ? 1 : -1;
    });
  }

  /** Get the price from the price scale in function of the family type */
  private getPriceFromType(typeFamille: string): number {
    if (typeFamille == "gaz basse température") {
      return this.priceScale.baremeGazBasseTemperature;
    } else if (typeFamille == "gaz condensation") {
      return this.priceScale.baremeGazCondensation;
    } else if (typeFamille == "eaux chaudes basse température") {
      return this.priceScale.baremeEauxChaudesBasseTemperature;
    } else if (typeFamille == "eaux chaudes condensation") {
      return this.priceScale.baremeEauxChaudesCondensation;
    } else if (typeFamille == "autres") {
      return 0;
    }
  }

  /** Get the amount from the price scale in function of the family type (if exists) */
  private getAmountFromType(typeFamille: string): number {
    if (typeFamille == "gaz basse température") {
      return this.priceScale.montantGazBasseTemperature;
    } else if (typeFamille == "gaz condensation") {
      return this.priceScale.montantGazCondensation;
    } else if (typeFamille == "eaux chaudes basse température") {
      return this.priceScale.montantEauxChaudesBasseTemperature;
    } else if (typeFamille == "eaux chaudes condensation") {
      return this.priceScale.montantEauxChaudesCondensation;
    }
  }

  public getTotalNumberOfDevices() {
    if (this.billRecap.length < 1) {
      throw new Error('source contains no elements');
    }
    return this.billRecap.reduce((ty, bR) => ty + bR.numberOfItems, 0);
  }

  public getTotalPrice() {
    if (this.billRecap.length < 1) {
      throw new Error('source contains no elements');
    }
    return this.billRecap.reduce((ty, bR) => ty + bR.totalPrice, 0);
  }
}
