import { startWith, tap } from 'rxjs/operators';
import { UrlService } from '../../core/services/url.service';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'choose-declaration',
  templateUrl: './choose-declaration.component.html',
  styleUrls: ['./choose-declaration.component.scss']
})
export class ChooseDeclarationComponent implements OnInit {

  clubDegrePlusUrl$ : Observable<string>;

  constructor(private urlService : UrlService) {}

  ngOnInit(): void {
    this.clubDegrePlusUrl$ = this.urlService.getClubUrl().pipe(
      startWith('#'),
    );
  }
}
