import { SentBillResponse } from './../models/sent-bill-response';
import { BillDetail } from './../models/bill-detail';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class BillManagementService {

  constructor(private http : HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getBills(status : string, searchString : string){
    return this.http.get(this.baseUrl + 'api/bill/bills?status='+status+'&searchString='+searchString).pipe();
  }

  getBillDetail(billId: number){
    return this.http.get<BillDetail>(this.baseUrl + 'api/bill/billDetail?billId='+billId).pipe();
  }

  validateBill(fd : FormData){
    return this.http.post<SentBillResponse>(this.baseUrl + 'api/bill/validateBill', fd).pipe();
  }

  refuseBill(fd : FormData){
    return this.http.post<SentBillResponse>(this.baseUrl + 'api/bill/refuseBill', fd).pipe();
  }
}
