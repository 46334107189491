import { Observable } from 'rxjs';
import { PriceScale } from '../../models/price-scale';
import { BillService } from '../../services/bill.service';
import { BillRecap } from '../../models/bill-recap';
import { Router } from '@angular/router';
import { DeclarationHistoryItem } from '../../models/declaration-history-item';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'bill-recap',
  templateUrl: './bill-recap.component.html',
  styleUrls: ['./bill-recap.component.scss']
})
export class BillRecapComponent implements OnInit {

  declarations: DeclarationHistoryItem[] = [];
  billRecap: BillRecap[]

  priceScale$: Observable<PriceScale>;

  selectedFile: File = null;

  isSubmitting: boolean = false;
  fileName: string;
  billNumber: string;
  comment: string;

  constructor(private router: Router, private billService: BillService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.priceScale$ = this.billService.getPriceScale();

    if ('declarations' in history.state) {
      this.declarations = history.state.declarations;
    }
  }

  /** Redirects on Cancel button */
  redirectToDeclarationHistory() {
    this.router.navigateByUrl("/declaration-history");
  }

  /** When a file is selected in the input */
  onFileSelected(event) {
    this.selectedFile = event.target.files[0];
    this.fileName = this.selectedFile.name;
  }

  /** When the bill is sent */
  onUpload() {

    this.isSubmitting = true;

    const fd = new FormData();
    fd.append('declarations', JSON.stringify(this.declarations));
    fd.append('files', this.selectedFile, this.selectedFile.name);
    fd.append('billNumber', this.billNumber);
    if (!!this.comment) { fd.append('comment', this.comment) };

    this.billService.sendBill(fd)
      .subscribe(res => {
        this.isSubmitting = false;
        if (res.success) {
          this.showSentBillSuccessToastr();
          setTimeout(() => {
            this.router.navigateByUrl('/declaration-history');
          }, 1000);
        }
      });
  }

  /** Gets the reference from the complete serial number */
  private getReference(serialNumber: string): string {
    return serialNumber.substring(16, 26);
  }

  /** Checks if the selected is in the pdf format */
  fileIsPdf(): boolean {
    return this.selectedFile.type == "application/pdf";
  }

  showSentBillSuccessToastr() {
    this.toastr.success('Votre facture a été envoyée.');
  }
}
