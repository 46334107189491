import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(private router: Router, private oidcSecurityService: OidcSecurityService) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.oidcSecurityService.isAuthenticated$.pipe(
      map((authResult) => {
        console.log('AuthorizationGuard, canActivate isAuthorized: ' + authResult.isAuthenticated);
        if (authResult.isAuthenticated) {
          return true;
        }
        this.router.navigate(['/login']);
      }),
      first()
    );
  }
}
