import { BillModule } from './../bill/bill.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { BillManagementComponent } from './bill-management/bill-management.component';
import { BillDetailComponent } from './bill-detail/bill-detail.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [BillDetailComponent, BillManagementComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    BillModule,
  ]
})
export class AdminModule { }
