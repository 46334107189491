import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {

  readonly API_URL: string = "https://geo.api.gouv.fr";

  constructor(private http: HttpClient) { }

  getDepartements() {
   return this.http.get<any>(this.API_URL+"/departements");
  // return this.http.get<any>('https://geo.api.gouv.fr/departements');

  }

  getCity(city: string):any {
    return this.http.get<any>(this.API_URL+"/communes?nom=" + city + "&boost=population&limit=10");
  }
}
