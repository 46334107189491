import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'serialNumber'
})
export class SerialNumberPipe implements PipeTransform {

  transform(serialNumber: string, ...args: unknown[]): string {
    if (!serialNumber) { return ''; }

    serialNumber = serialNumber.toUpperCase();

    const lastChar = serialNumber.slice(-1);
    const slicedString = serialNumber.slice(0, -1);

    if (serialNumber.length === 5
      || serialNumber.length === 9
      || serialNumber.length === 16
    ) {
      if (lastChar !== '-') {
        serialNumber = slicedString + '-' + lastChar;
      }
    }

    return serialNumber;
  }

}
