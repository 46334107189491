import { BillService } from './../../services/bill.service';
import { DeclarationHistoryItem } from './../../models/declaration-history-item';
import { UrlService } from './../../core/services/url.service';
import { shareReplay, switchMap, map, tap, share, startWith } from 'rxjs/operators';
import { UserService } from './../../core/services/user.service';
import { Observable, iif, of } from 'rxjs';
import { PermissionService } from '../../core/services/permission.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { DeclarationHistoryService } from '../../services/declaration-history.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { DeclarationHistoryDataSource } from './declaration-history-datasource';
import { SelectionModel, DataSource } from '@angular/cdk/collections';

@Component({
  selector: 'app-declaration-history',
  templateUrl: './declaration-history.component.html',
  styleUrls: ['./declaration-history.component.scss']
})
export class DeclarationHistoryComponent implements OnInit, AfterViewInit {
  // dataSource: DeclarationHistoryDataSource;
  dataSource = new MatTableDataSource<DeclarationHistoryItem>();

  isLoading: boolean = true;
  isLoadingBillable: boolean = false;
  selection: SelectionModel<DeclarationHistoryItem>;
  showSelection: boolean = false;

  length: number;
  pageSize: number = 10;
  pageIndex: number = 0;

  // For the modal
  closeResult = '';
  modalTitle: string;
  modalContent: string;

  // Observables
  hasPriceScale$: Observable<boolean>;
  isStabe$: Observable<boolean>;
  isCubeAdmin$: Observable<boolean>;
  isChefEntreprise$: Observable<boolean>;

  canUploadBill$: Observable<boolean>;
  inviteStabeToRegisterClub$: Observable<boolean>;

  clubUrl$: Observable<string>

  showSelectButton: boolean = false;

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  //displayedColumns = ['date', 'type', 'qui', 'numeroSerie', 'produit', 'marque', 'ville', 'departement', 'statut', 'points', 'select'];
  displayedColumns = ['date', 'type', 'qui', 'numeroSerie', 'produit', 'marque', 'ville', 'departement', 'statut', 'select'];


  constructor(private router: Router, private declarationHistoryService: DeclarationHistoryService, private modalService: NgbModal, private permissionsService: PermissionService, private userService: UserService, private urlService: UrlService, private billService : BillService) {
    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<DeclarationHistoryItem>(allowMultiSelect, initialSelection);
  }

  ngOnInit() {
    this.clubUrl$ = this.urlService.getClubUrl().pipe(
      startWith('#'),
    );

    this.hasPriceScale$ = this.billService.hasExistingPriceScale();

    this.isStabe$ = this.userService.isStabe().pipe(shareReplay(1));
    this.isCubeAdmin$ = this.userService.isCubeAdmin().pipe(shareReplay(1));

    // if role ChefEntreprise is present, put the points column in penultimate
    this.isChefEntreprise$ = this.permissionsService.hasPermission('ChefEntreprise')
      .pipe(shareReplay(1));

    this.canUploadBill$ = this.isCubeAdmin$.pipe(
      shareReplay(1),
      switchMap(isCb =>
        iif(() => isCb,
          this.isStabe$.pipe(switchMap(isSt => iif(() => isSt, this.isChefEntreprise$, of(true)))),
          of(false)
        )
      )
    )

    this.inviteStabeToRegisterClub$ = this.isCubeAdmin$.pipe(
      shareReplay(1),
      switchMap(isCb =>
        iif(() => isCb,
          this.isStabe$.pipe(
            switchMap(isSt => iif(() => isSt,
              this.isChefEntreprise$.pipe(
                map(isCe => !isCe)
              ),
              of(false)))),
          of(false)
        )
      )
    )

    this.isChefEntreprise$.subscribe(
      res => {
        if (res)
          this.displayedColumns.splice(this.displayedColumns.length - 2, 0, 'points');
      }
    )
  }

  ngAfterViewInit() {

    this.refreshData();
  }

  refreshData(pageIndex?: number) {

    if(!!pageIndex)
      this.pageIndex = pageIndex;

    this.isLoading = true;

    // Get the history from server
    this.declarationHistoryService.getAllDeclarationHistory(this.pageSize, this.pageIndex + 1)
      .subscribe(res => {
        this.dataSource.data = res.items as DeclarationHistoryItem[];
        this.length = res.tableInfo.totalCount;

        this.isLoading = false;
      });
  }

  handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;

    this.refreshData();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Returns whether or not a row of the declaration history is selectable for the bill (commissioning declarations) */
  isSelectableForBill(row) {
    return (row.type == "Mise en service" && (row.statut == "À facturer" || row.statut == "Facture refusée"));
  }

  /** When the button is clicked, displays the selects in the page */
  selectDeclarationsForBill() {

    this.isLoadingBillable = true;;

    this.pageIndex = 0;

    // Set showSelection property to true
    this.showSelection = true;

    this.declarationHistoryService.getBillableDeclarations().subscribe(
      res => {
        console.log(res);
        this.dataSource.data = res.items
      },
      error => {},
      () => this.isLoadingBillable = false
    )

    //this.showDeclarations('billable');
  }

  /** Close the select of the billable declarations */
  closeDeclarationsForBill() {
    this.showSelection = false;
    this.refreshData(0);
  }


  /** Returns only commissioning declarations that have the status 'À facturer' or 'Facture refusée' */
  private getBillableDeclarations(data: DeclarationHistoryItem[]): DeclarationHistoryItem[] {
    let billableDeclarations: DeclarationHistoryItem[] = []
    data.forEach(element => {
      if (this.isSelectableForBill(element)) {
        billableDeclarations.push(element);
      }
    });
    return billableDeclarations;
  }

  private countOfBillableDeclarations(data: DeclarationHistoryItem[]): number {
    let count = 0;
    data.forEach(element => {
      if (this.isSelectableForBill(element)) {
        count += 1;
      }
    });
    return count;
  }

  /** Remove a certain array element */
  private removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  prepareBill() {
    this.router.navigateByUrl('/declaration-history/bill-recap', { state: { declarations: this.selection.selected } });
  }

  /** Modal functions */

  statusRefuseeClicked(content, row: DeclarationHistoryItem) {
    this.open(content);
    console.log(row);
    this.modalTitle = 'Raison du refus de la facture :';
    this.modalContent = row.statusAnnotation;
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basi8c-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  transformDate(date) {
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'dd/MM/yyyy').toString();
  }
}
