import { AdminModule } from './admin/admin.module';
import { BillModule } from './bill/bill.module';
import { DeclarationModule } from './declaration/declaration.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpBackend, HttpEventType } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PointsNotificationComponent } from './points-notification/points-notification.component'
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckbox, MatCheckboxModule } from '@angular/material/checkbox'
import { ToastrModule } from 'ngx-toastr';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  declarations: [
    AppComponent,
    PointsNotificationComponent,
    NavbarMenuComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    CoreModule,
    SharedModule,
    NgbModalModule,
    DeclarationModule,
    BillModule,
    AdminModule,
    ToastrModule.forRoot(),
  ],
  // providers: [DatePipe, { provide: LOCALE_ID, useValue: 'en-US' }],
  providers: [
    { provide: LOCALE_ID, useValue: 'fr' }
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
