<div class="mat-elevation-z8 declaration-history bill-management">
  <div class="d-flex align-items-end mt-2">
    <select #selectStatus class="bill-management__title form-control" [(ngModel)]="status"
      (change)="initializeDataTable()">
      <option value="Facture reçue">Factures reçues</option>
      <option value="Facture validée">Factures validées</option>
      <option value="Facture refusée">Factures refusées</option>
      <option value="Toutes les factures">Toutes les factures</option>
    </select>
    <form (ngSubmit)="onSubmit()" #searchStringForm="ngForm" class="ml-auto d-flex justify-content-end align-items-end">
      <input type="text" class="form-control" id="searchString" [(ngModel)]="searchString_" name="searchString" #searchString="ngModel" placeholder="Rechercher une facture...">
      <button class="btn btn-small btn-primary">Rechercher</button>  
    </form>
  </div>
  <table [hidden]="isLoading" [dataSource]="dataSource" mat-table class="full-width-table" matSort
    aria-label="Elements">

    <!-- Name Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reçue le</th>
      <td mat-cell *matCellDef="let row">{{row.date | date:'dd/MM/yyyy'}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="organisation">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Société</th>
      <td mat-cell *matCellDef="let row">{{row.organisation}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="numeroFacture">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Numéro facture</th>
      <td mat-cell *matCellDef="let row">{{row.numeroFacture}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="nombreDeclarations">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nombre de mises en service</th>
      <td mat-cell *matCellDef="let row">{{row.nombreDeclarations}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="marques">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Marque(s)</th>
      <td mat-cell *matCellDef="let row">{{row.marques}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="statut">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Statut</th>
      <td mat-cell *matCellDef="let row">{{row.statut}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="modifieur">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{status == 'Facture validée' ? 'Validée par' : 'Refusée
        par'}}</th>
      <td mat-cell *matCellDef="let row">{{row.modifieur}}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="dateAction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{status == 'Facture validée' ? 'Validée le' : 'Refusée
        le'}}</th>
      <td mat-cell *matCellDef="let row">{{row.dateAction | date:'dd/MM/yyyy'}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="bill-management__row--clickable" [routerLink]="['/bill-management', row.idFacture]" mat-row
      *matRowDef="let row; columns: displayedColumns;"></tr>
    <!-- <div *ngIf="selectStatus.value == 'Facture reçue'; then clickable_td else unclickable_td">here is ignored</div>
    <ng-template #clickable_td>
      <tr class="bill-management__row--clickable" [routerLink]="['/bill-management', row.idFacture]" mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
    </ng-template>
    <ng-template #unclickable_td>
      <tr class="bill-management__row" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </ng-template> -->
  </table>

  <div [ngClass]="{'d-flex': isLoading, 'd-none': !isLoading}"
    class="declaration-history__loader loader flex-row justify-content-center mt-5">
    <div>
      <span class="loader__title">Chargement des factures...</span>
      <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
    </div>
  </div>

  <mat-paginator [hidden]="isLoading" #paginator [length]="dataSource?.data.length" [pageIndex]="0" [pageSize]="10">
  </mat-paginator>
</div>