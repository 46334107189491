<ul class="nav">
  <li class="nav-item">
    <a class="nav-link" routerLink="/declaration" routerLinkActive="active">Déclarer</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" routerLink="/declaration-history" routerLinkActive="active">Mes déclarations</a>
  </li>
  <li class="nav-item" *ngxPermissionsOnly="['AdministrateurDeclarationAppareil']">
    <a class="nav-link" routerLink="/bill-management" routerLinkActive="active">Gestion des factures</a>
  </li>
</ul>