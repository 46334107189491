import { DatePipe } from '@angular/common';
import { BillManagementItem } from '../../models/bill-management-item';
import { BillManagementService } from '../../services/bill-management.service';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { BillManagementDataSource } from './bill-management-datasource';

@Component({
  selector: 'app-bill-management',
  templateUrl: './bill-management.component.html',
  styleUrls: ['./bill-management.component.scss']
})
export class BillManagementComponent implements AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<BillManagementItem>;
  dataSource: BillManagementDataSource;
  isLoading: boolean = true;

  status: string = "Facture reçue";
  searchString_ : string = '';

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  displayedColumns = ['date', 'organisation', 'numeroFacture', 'nombreDeclarations', 'marques', 'statut', 'modifieur', 'dateAction'];

  constructor(private billManagementService: BillManagementService) { }

  ngAfterViewInit() {
    this.initializeDataTable();
  }

  initializeDataTable() {
    this.isLoading = true;

    if (this.status != 'Toutes les factures'){
      this.searchString_= '';
    }

    // remove or add columns
    if (this.status == 'Facture reçue' || this.status == 'Toutes les factures') {
      const index = this.displayedColumns.indexOf('modifieur', 0);
      if (index > -1) {
        this.displayedColumns.splice(index, 1);
      }
      const index2 = this.displayedColumns.indexOf('dateAction', 0);
      if (index > -1) {
        this.displayedColumns.splice(index2, 1);
      }
    } else {
      if (!this.displayedColumns.includes('modifieur'))
        this.displayedColumns.push('modifieur');
      if (!this.displayedColumns.includes('dateAction'))
        this.displayedColumns.push('dateAction');
    }

    this.dataSource = new BillManagementDataSource();

    // Get the history from server
    let resp = this.billManagementService.getBills(this.status, this.searchString_);
    resp.subscribe(report => {
      this.dataSource.data = report as BillManagementItem[];
      this.isLoading = false;
    });

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.table.dataSource = this.dataSource;
  }

  transformDate(date) {
    let datePipe = new DatePipe('en-US');
    return datePipe.transform(date, 'dd/MM/yyyy').toString();
  }

  onSubmit(){
    if (!!this.searchString_)
    {
      this.status = 'Toutes les factures';
      this.initializeDataTable();
    }
  }

  onClick(){
    console.log("searchString", this.searchString_);
  }
}
