<!-- <body>
    <app-nav-menu></app-nav-menu>
    <div class="body-content">
        <navbar-menu></navbar-menu>
        <router-outlet></router-outlet>
    </div>
</body>
<app-footer></app-footer> -->

<div style="min-height: 100%;">
    <app-nav-menu></app-nav-menu>
    <div class="body-content">
        <navbar-menu></navbar-menu>
        <router-outlet></router-outlet>
    </div>
</div>
<!--<app-footer></app-footer>-->
