import { Document } from './../../models/document';
import { DeclarationHistoryItem } from './../../models/declaration-history-item';
import { BillService } from './../../services/bill.service';
import { Component, Input, OnInit } from '@angular/core';
import { Utility } from 'src/app/models/utility';

@Component({
  selector: 'app-declaration-table',
  templateUrl: './declaration-table.component.html',
  styleUrls: ['./declaration-table.component.scss']
})
export class DeclarationTableComponent implements OnInit {

  @Input() declarations : DeclarationHistoryItem[];
  @Input() productType : string;

  isDownlaoding : boolean[];

  constructor(private billService : BillService) { }

  ngOnInit(): void {
    this.isDownlaoding = this.declarations.map(_ => false);
  }

  public getDeclarationsFromProductType(){
    return this.declarations.filter(x => x.typeFamille == this.productType)
  }

  downloadDocument(declaration : DeclarationHistoryItem, i: number){
    this.isDownlaoding[i] = true;
    this.billService.getDocument(declaration.idDeclaration).subscribe(
      res => {
        Utility.downloadFile(res);
        this.isDownlaoding[i] = false;
      }
    );
  }
}
