import { DeviceInfo } from '../../models/device-info';
import { GeolocationService } from '../../services/geolocation.service';
import { SucceededDeclarationInfo } from '../../models/succeeded-declaration-info';
import { catchError, finalize, first } from 'rxjs/operators';
import { DeclarationService } from '../../services/declaration.service';
import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { InstallationDeclaration } from '../../models/installation-declaration';
import { throwError, Observable, Subscription } from 'rxjs';
import { PromotionReglementeeModel } from 'src/app/models/promotion-reglementee.model';
import { GiftModel } from 'src/app/models/gift.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { SentDeclarationResponse } from 'src/app/models/sent-declaration-response';
import { SerialNumberPipe } from 'src/app/shared/pipes/serial-number.pipe';
import { InstallationDeclarationModel } from 'src/app/models/installation-declaration.model';

export function maxDateValidator(maxDate: Date | string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (new Date(control.value).getTime() > new Date(maxDate).getTime()) {
      return { afterMax: true };
    }
    return null;
  };
}

export function minDateValidator(minDate: Date | string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (new Date(control.value).getTime() < new Date(minDate).getTime()) {
      return { beforeMin: true };
    }
    return null;
  };
}

@Component({
  selector: 'installation-declaration',
  templateUrl: './installation-declaration.component.html',
  styleUrls: ['./installation-declaration.component.scss'],
  providers: [SerialNumberPipe]
})
export class InstallationDeclarationComponent implements OnInit, OnDestroy {

  declarationForm?: UntypedFormGroup;
  dateInstallationSubscription = new Subscription();
  numeroSerieSubscription = new Subscription();
  numeroRobinetSubscription = new Subscription();

  today: Date = new Date();
  minDate: Date;

  // pour afficher le numéro de plaque de robinetterie
  isDisplay = false;
  isSelected = false;

  isSubmitting = false;
  hasSubmitError = false;
  hasPostSubmitError = false;
  errorStatus: string;
  errorStatusText: string;

  // The list of departments within our dropdown
  departements: any[];

  deviceInfo$: Observable<DeviceInfo>;

  eligiblePromotions: PromotionReglementeeModel[] = [];

  promoForm?: UntypedFormGroup;
  giftList: GiftModel[] = [];
  chosenPromotionSubscription = new Subscription();
  promoFormSubscription = new Subscription();
  participateSubscription = new Subscription();
  purchaseProof?: File;
  isCheckingPromotions = false;
  isPromoFormValid = true;
  chosenPromotion = {} as PromotionReglementeeModel;

  serialNumber = '';
  numeroPlaqueRobinet = '';
  serialNumberPattern: RegExp = /^[\w]{4}-[\w]{3}-[\w]{6}-[\w]{10}$/;

  constructor(
    private router: Router,
    private declarationService: DeclarationService,
    private geolocationService: GeolocationService,
    private formBuilder: UntypedFormBuilder,
    private serialNumberPipe: SerialNumberPipe
  ) {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDay() - 730 ;
    this.minDate = new Date(currentYear, currentMonth, currentDay);
  }

  ngOnDestroy(): void {
    this.chosenPromotionSubscription.unsubscribe();
    this.promoFormSubscription.unsubscribe();
    this.participateSubscription.unsubscribe();
    this.dateInstallationSubscription.unsubscribe();
    this.numeroRobinetSubscription.unsubscribe();
    this.numeroSerieSubscription.unsubscribe();
  }

  ngOnInit(): void {
    // Initialize the list of departments
    this.geolocationService.getDepartements().subscribe(
      data => {
        this.departements = data;
      }
    );
    this.buildDeclarationForm();
    this.listenToDeclarationFormEvents();
    this.buildPromoForm();
    this.listenToPromoFormEvents();
  }

  onFileChange(event: any): void {
    this.purchaseProof = event.target.files[0];
    if (!(this.purchaseProof?.type === 'image/jpeg'
      || this.purchaseProof?.type === 'image/png'
      || this.purchaseProof?.type === 'application/pdf')
    ) {
      this.f.purchaseProofFile.setErrors({ required: true });
      this.isPromoFormValid = false; // required because onFileChange is called after the valueChange event on promoForm
    } else {
      this.f.purchaseProofFile.setErrors(null);
    }
  }

  // Yes pour le champs numéro plaque de robinetterie
  onDisplay(): void {
    this.isDisplay = true;
  }

  // Non pour le champs numéro plaque de robinetterie
  onDisplayFalse(): void {
    this.isDisplay = false;
  }

  // Submits the installation declaration
  onSubmit(): void {
    if (
      this.declarationForm?.invalid
      || (this.eligiblePromotions?.length && this.promoForm?.invalid)
    ) {
      return;
    }

    const data = {} as InstallationDeclarationModel;
    Object.assign(data, this.declarationForm.value);
    data.giftId = +this.f.giftId.value;
    data.promotionId = +this.f.promotionId.value;

    this.isSubmitting = true;
    this.hasSubmitError = false;
    this.hasPostSubmitError = false;

    if (this.eligiblePromotions?.length && this.f.participate.value === 'yes') {
      this.declareInstallationWithPromotion(data);
    } else {
      data.promotionId = null;
      data.giftId = null;
      this.declareInstallation(data);
    }
  }

  /** When scan is a successful, we update the serial number */
  setSerialNumberFromScan(serialNumber): void {
    this.d.numeroSerie.setValue(serialNumber);
    this.checkSerialNumber();
    this.getEligiblePromotions();
  }

  setDosseretSerialNumberFromScan(serialNumber): void {
    this.d.numeroPlaqueRobinet.setValue(serialNumber);
  }

  get d() {
    return this.declarationForm.controls;
  }

  get f() {
    return this.promoForm.controls;
  }

  private getEligiblePromotions(): void {
    if (this.d.numeroSerie.valid
      && this.d.numeroSerie.value
      && this.d.dateInstallation.value
    ) {
      this.isCheckingPromotions = true;
      this.eligiblePromotions = [];
      this.promoForm.reset();

      this.declarationService
        .getEligiblePromotionsReglementees(this.d.numeroSerie.value, this.d.dateInstallation.value)
        .pipe(
          first(),
          finalize(() => this.isCheckingPromotions = false)
        ).subscribe((promotions) => {
          this.eligiblePromotions = promotions;
          if (this.eligiblePromotions?.length) {
            this.f.participate.setValue('yes');
          }
          this.setPromoFormValidators();
        });
    }
  }

  private setPromoFormValidators(): void {
    if (this.eligiblePromotions?.length && this.f.participate.value === 'yes') {
      this.f.promotionId.setValidators(Validators.required);
      this.f.giftId.setValidators(Validators.required);
      if (this.chosenPromotion?.purchaseProofRequired) {
        this.f.purchaseProofFile.setValidators(Validators.required);
      }
      this.isPromoFormValid = false;
    } else {
      this.f.promotionId.clearValidators();
      this.f.giftId.clearValidators();
      this.f.purchaseProofFile.clearValidators();
      this.isPromoFormValid = true;
    }
    this.f.promotionId.updateValueAndValidity({ emitEvent: false });
    this.f.giftId.updateValueAndValidity();
    this.f.purchaseProofFile.updateValueAndValidity();
  }

  /** Check the serial number and gets the information about the device */
  private checkSerialNumber(): void {
    this.deviceInfo$ = this.declarationService
      .checkSerialNumber(this.d.numeroSerie.value, 'installation', this.d.dateInstallation.value)
      .pipe(first());
  }

  private listenToDeclarationFormEvents(): void {
    this.numeroSerieSubscription = this.d.numeroSerie.valueChanges.subscribe((numeroSerie) => {
      if (this.d.numeroSerie.errors?.pattern && numeroSerie !== this.serialNumber) {
        this.serialNumber = numeroSerie;
        this.d.numeroSerie.setValue(this.serialNumberPipe.transform(numeroSerie));
      }

      if (this.d.numeroSerie.valid) {
        this.verifySerialNumberAndPromotions();
        this.getNumeroPlaqueRobinetterie();
      }
    });

    this.numeroRobinetSubscription = this.d.numeroPlaqueRobinet.valueChanges.subscribe(numeroPlaque => {
      if (this.d.numeroPlaqueRobinet.errors?.pattern && numeroPlaque !== this.numeroPlaqueRobinet) {
        this.numeroPlaqueRobinet = numeroPlaque;
        this.d.numeroPlaqueRobinet.setValue(this.serialNumberPipe.transform(numeroPlaque));
      }
    });

    this.dateInstallationSubscription = this.d.dateInstallation.valueChanges.subscribe((date) => {
      this.getEligiblePromotions();
    });
  }

  private buildDeclarationForm(): void {
    this.declarationForm = this.formBuilder.group({
      numeroSerie: ['', [
        Validators.required,
        Validators.pattern(this.serialNumberPattern),
        Validators.maxLength(26)
      ]],
      numeroPlaqueRobinet: ['', [
        Validators.pattern(this.serialNumberPattern),
        Validators.maxLength(26)
      ]],
      dateInstallation: [this.today.toISOString().substring(0, 10), [
        Validators.required,
        maxDateValidator(this.today),
        minDateValidator(this.minDate)
      ]],
      dateFactureAchat: ['', [
        Validators.required,
        maxDateValidator(this.today)
      ]],
      //departement: ['', Validators.required],
      departement: ['', Validators.required],
      distributeur: ['', Validators.required],
      adresseInstallation: ['', Validators.required],
      villeInstallation: ['', Validators.required],
      codePostalInstallation: ['', [
        Validators.required,
        Validators.pattern('[0-9]{5}'),
        Validators.maxLength(5)
      ]]
    });
  }

  private listenToPromoFormEvents(): void {
    this.chosenPromotionSubscription = this.f.promotionId.valueChanges.subscribe(
      (promoId) => {
        this.chosenPromotion = this.eligiblePromotions?.find((promo) => promo.idPromotion === +promoId);
        if (this.chosenPromotion) {
          this.giftList = this.chosenPromotion.gifts;
          this.f.giftId.setValue(this.giftList[0].id);
        }
        this.setPromoFormValidators();
      }
    );
    this.participateSubscription = this.f.participate.valueChanges.subscribe(() => this.setPromoFormValidators());
  }

  private buildPromoForm(): void {
    this.promoForm = this.formBuilder.group({
      promotionId: [''],
      giftId: [''],
      purchaseProofFile: [''],
      participate: ['']
    });

    this.promoFormSubscription = this.promoForm.valueChanges.subscribe(
      () => {
        if (this.promoForm.invalid) {
          this.isPromoFormValid = false;
        } else {
          this.isPromoFormValid = true;
        }
      }
    );
  }

  private declareInstallationWithPromotion(data: InstallationDeclaration): void {
    const formData = new FormData();
    formData.append('declaration', JSON.stringify(data));
    if (this.purchaseProof) {
      formData.append('purchaseProof', this.purchaseProof, this.purchaseProof.name);
    }
    this.declarationService.declareInstallationWithPromotion(formData)
      .pipe(
        first(),
        catchError(err => this.handleError(err))
      )
      .subscribe(result => this.handleSuccess(result));
  }

  private declareInstallation(data: InstallationDeclaration): void {
    this.declarationService.declareInstallation(data)
      .pipe(
        first(),
        catchError(err => this.handleError(err))
      )
      .subscribe(result => this.handleSuccess(result));
  }

  private handleSuccess(result: SentDeclarationResponse): void {
    this.isSubmitting = false;

    if (result.success) {
      this.router.navigateByUrl('/declaration/succeeded-declaration',
        {
          state:
          {
            info: new SucceededDeclarationInfo('installation',
              result.showPointsNotification,
              result.hasDuplicateSerialNumberAnomaly,
              result.pointsEarned,
              result.newBalance
            )
          }
        });
    }
    else {
      this.errorStatusText = result.message;
      this.hasPostSubmitError = true;
    }
  }

  private handleError(err: any): Observable<never> {
    this.isSubmitting = false;
    this.hasSubmitError = true;
    this.errorStatus = err.status;
    this.errorStatusText = err.statusText;
    this.promoForm.reset();
    return throwError(() => new Error(err));
  }

  private verifySerialNumberAndPromotions(): void {
    this.checkSerialNumber();
    this.getEligiblePromotions();
  }

  // Si le champs numéro de série est valide envoi d'une requête pour récupérer
  // le numéro de plaque de robinetterie
  private getNumeroPlaqueRobinetterie(): void {
    this.declarationService.checkNumeroPlaqueRobinetterie(this.d.numeroSerie.value).subscribe(
      result => {
        if (!result.empty) {
          this.isSelected = true;
          this.onDisplay();
          this.d.numeroPlaqueRobinet.setValue(result.numeroPlaqueRobinetterie);
        }
      });
  }
}
