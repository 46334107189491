<div *ngIf="priceScale$ | async as priceScale; else loading" [class.disabled]="isSubmitting" class="bill-recap pb-2">
    <h1 class="bill-recap__title">Récapitulatif facture</h1>

    <div *ngIf="declarations.length > 0">
        <div class="bill-recap__explaination d-flex flex-row">
            <svg class="bill-recap__icon bill-recap__icon--info-i-frame" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 192">
                <path
                    d="M96 176c-44.112 0-80-35.888-80-80s35.888-80 80-80 80 35.888 80 80-35.888 80-80 80zm0-152c-39.701 0-72 32.299-72 72s32.299 72 72 72 72-32.299 72-72-32.299-72-72-72zm-7.1 39.1c0-3.9 3.2-7.1 7.1-7.1 4 0 7.1 3.1 7.1 7.1s-3.1 7.1-7.1 7.1c-3.9 0-7.1-3.2-7.1-7.1zm1.85 17.3h10.5V136h-10.5V80.4z" />
            </svg>
            <span>Vous pouvez utiliser les informations ci-dessous pour établir votre facture.</span>
        </div>

        <app-bill-recap-table [declarations]="declarations" [priceScale]="priceScale" [isSticky]="false">
        </app-bill-recap-table>

        <form class="form" #billForm="ngForm">
            <div class="form-group">
                <label for="bill">
                    <div class="bill-recap__upload d-flex flex-row">
                        <svg class="bill-recap__icon bill-recap__icon--document-arrow-up"
                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                            <path
                                d="M120 80H56v-8h64v8zm0 12H56v8h64v-8zm-64 28h40v-8H56v8zm120 24c0 17.645-14.355 32-32 32s-32-14.355-32-32 14.355-32 32-32 32 14.355 32 32zm-8 0c0-13.234-10.766-24-24-24s-24 10.766-24 24 10.766 24 24 24 24-10.766 24-24zm-38.829-2.829l5.658 5.658 5.171-5.172V160h8v-18.343l5.171 5.172 5.658-5.658L144 126.343l-14.829 14.828zM120 175.978V176H40V16h81.815L152 50.497v54.308a40.027 40.027 0 0 0-8-.805V56h-28V24H48v144h64.022a40.342 40.342 0 0 0 7.978 7.978zM124 48h15.185L124 30.646V48z" />
                        </svg>
                        <span>Joindre ma facture</span>
                    </div>
                </label>
                <input [hidden]="true" required type="file" class="form-control-file form-control-lg" id="bill"
                    (change)="onFileSelected($event)">
            </div>
            <div class="bill-recap__file-validation">
                <div [hidden]="selectedFile == null" class="bill-recap__file-name">
                    Fichier choisi : {{fileName}}
                </div>
                <div [hidden]="selectedFile == null" class="alert alert-warning">
                    <b>Attention : </b>La facture mise en ligne ci-dessus ne doit pas être envoyée par courrier en parallèle.
                </div>
                <div [hidden]="selectedFile == null || fileIsPdf()" class="alert alert-danger">
                    La facture que vous nous envoyez doit être au format <b>PDF</b>.
                </div>
            </div>
            <div class="bill-recap__explaination-bill-number d-flex flex-row">
                <svg class="bill-recap__icon bill-recap__icon--info-i-frame" xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 192 192">
                    <path
                        d="M96 176c-44.112 0-80-35.888-80-80s35.888-80 80-80 80 35.888 80 80-35.888 80-80 80zm0-152c-39.701 0-72 32.299-72 72s32.299 72 72 72 72-32.299 72-72-32.299-72-72-72zm-7.1 39.1c0-3.9 3.2-7.1 7.1-7.1 4 0 7.1 3.1 7.1 7.1s-3.1 7.1-7.1 7.1c-3.9 0-7.1-3.2-7.1-7.1zm1.85 17.3h10.5V136h-10.5V80.4z" />
                </svg>
                <span>Veuillez renseigner ci-dessous le même numéro de facture que dans le fichier que vous nous joignez. Cela
                    nous aidera à retrouver plus facilement votre facture en cas d'anomalie.</span>
            </div>
            <div class="form-group bill-number">
                <label for="billNumber">Votre numéro de facture</label>
                <input type="text" required [(ngModel)]="billNumber" name="billNumber" #billNumberInput="ngModel"
                    id="billNumber" class="form-control">
                <div [hidden]="billNumberInput.valid || billNumberInput.pristine" class="alert alert-danger">
                    Le numéro de facture est requis.
                </div>
            </div>
            <div class="form-group comment">
                <label for="comment">Commentaire (facultatif)</label>
                <textarea [(ngModel)]="comment" name="comment" #commentTextarea="ngModel" id="comment" class="form-control"
                    rows="3"></textarea>
            </div>
            <div class="d-flex flex-row">
                <button type="button" [disabled]="isSubmitting" routerLink="/declaration-history"
                    class="btn btn-secondary mr-1 ml-auto">Annuler</button>
                <button type="submit"
                    [disabled]="!billForm.valid || selectedFile == null || !fileIsPdf() || isSubmitting"
                    (click)="onUpload()" class="btn btn-primary">Envoyer</button>
            </div>
        </form>
    </div>

    <div *ngIf="declarations.length == 0">
        <span class="bill-recap__alert-text">Aucune déclaration n'a été
            sélectionnée.</span>
        <button routerLink="/declaration-history" class="d-block btn btn-secondary">Annuler</button>
    </div>
</div>

<div *ngIf="isSubmitting" class="loading">
    <span class="loading__title">Envoi de la facture...</span>
    <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
</div>

<ng-template #loading>
    <div class="loading">
        <span class="loading__title">Chargement des montants d'indemnité...</span>
        <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
    </div>
</ng-template>