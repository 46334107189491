import { DeclarationHistoryItem } from './../models/declaration-history-item';
import { TableItemContainer } from './../models/table-item-container';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeclarationHistoryContainer } from '../models/declaration-history-container';

@Injectable({
  providedIn: 'root'
})
export class DeclarationHistoryService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getDeclarationHistory(
    courseId: number, filter = '', sortOrder = 'asc',
    pageNumber = 0, pageSize = 3): Observable<DeclarationHistoryItem[]> {

    return this.http.get(this.baseUrl + '/api/history', {
      params: new HttpParams()
        .set('courseId', courseId.toString())
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
    }).pipe(
      map(res => res["payload"])
    );
  }

  getAllDeclarationHistory(pageSize? : number, page? : number): Observable<TableItemContainer<DeclarationHistoryItem>> {
    let queryParameters: string = '?';
    if (pageSize != null && pageSize != undefined)
      queryParameters += 'pageSize=' + pageSize + '&'
    if (page != null && page != undefined)
      queryParameters += 'page=' + page + '&'

    return this.http.get<TableItemContainer<DeclarationHistoryItem>>(this.baseUrl + 'api/declaration/history'+queryParameters);
  }

  getBillableDeclarations(): Observable<TableItemContainer<DeclarationHistoryItem>> {
    return this.http.get<TableItemContainer<DeclarationHistoryItem>>(this.baseUrl + 'api/declaration/billable');
  }
}