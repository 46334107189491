import { SalesTermsComponent } from './core/components/sales-terms/sales-terms.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { LegalNoticesComponent } from './core/components/legal-notices/legal-notices.component';
import { ImprintComponent } from './core/components/imprint/imprint.component';
import { MyBillComponent } from './bill/my-bill/my-bill.component';
import { authRoutes } from './core/auth.routes';
import { AuthorizeGuard } from './shared/guards/authorize.guard';
import { BillDetailComponent } from './admin/bill-detail/bill-detail.component';
import { BillManagementComponent } from './admin/bill-management/bill-management.component';
import { BillRecapComponent } from './bill/bill-recap/bill-recap.component';
import { CommissioningDeclarationComponent } from './declaration/commissioning-declaration/commissioning-declaration.component';
import { SucceededDeclarationComponent } from './declaration/succeeded-declaration/succeeded-declaration.component';
import { InstallationDeclarationComponent } from './declaration/installation-declaration/installation-declaration.component';
import { ChooseDeclarationComponent } from './declaration/choose-declaration/choose-declaration.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeclarationHistoryComponent } from './declaration/declaration-history/declaration-history.component';
import { LogoutComponent } from './core/components/logout/logout.component';


const appRoutes: Routes = [
  {
    path: 'declaration',
    component: ChooseDeclarationComponent,
  },
  {
    path: 'Imprint',
    component: ImprintComponent,
  },
  {
    path: 'MentionsLegales',
    component: LegalNoticesComponent,
  },
  {
    path: 'PolitiqueConfidentialite',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'ConditionsGeneralesVente',
    component: SalesTermsComponent,
  },
  {
    path: 'authentication/logout-callback',
    component: LogoutComponent
  },
  {
    path: 'declaration/installation-declaration',
    component: InstallationDeclarationComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'declaration/commissioning-declaration',
    component: CommissioningDeclarationComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'declaration/succeeded-declaration',
    component: SucceededDeclarationComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'declaration-history',
    component: DeclarationHistoryComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'declaration-history/bill-recap',
    component: BillRecapComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'declaration-history/bill/:billId',
    component: MyBillComponent,
    canActivate: [AuthorizeGuard],
  },
  {
    path: 'bill-management',
    component: BillManagementComponent,
    canActivate: [AuthorizeGuard],
    data: {
      permissions: {
        only: 'AdministrateurDeclarationAppareil'
      }
    }
  },
  {
    path: 'bill-management/:billId',
    component: BillDetailComponent,
    canActivate: [AuthorizeGuard],
    data: {
      permissions: {
        only: 'AdministrateurDeclarationAppareil'
      }
    }
  },
  {
    path: '',
    redirectTo: 'declaration',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes.concat(authRoutes),
      {
        scrollPositionRestoration: 'enabled',
        relativeLinkResolution: 'legacy',
        //enableTracing: true
      }
    ),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
