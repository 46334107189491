import { Injectable } from '@angular/core';
import { EventTypes, OidcClientNotification, OpenIdConfiguration, PublicEventsService } from 'angular-auth-oidc-client';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SecretService {

  tokenEndpoint: string;
  clientSecret: string;

  constructor(private eventService: PublicEventsService) {
    this.eventService
      .registerForEvents()
      .pipe(
        filter((notification) => notification.type === EventTypes.ConfigLoaded),
        map((notification: OidcClientNotification<OpenIdConfiguration>) => notification.value))
      .subscribe((config) => {
        this.tokenEndpoint = config.authWellknownEndpoints?.tokenEndpoint;
        this.clientSecret = config.customParamsAuthRequest?.client_secret as string;
        //console.log('ConfigLoaded with value ', config);
      });
  }
}
