import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { defaultIfEmpty, map, startWith, take, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlService {


  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { }

  getCubeUrl(): Observable<string> {
     return this.http.get<any>(this.baseUrl + 'api/url/cube').pipe(
       map(res => res.url),
       //shareReplay(1)
     );
  }

  getSignupUrl(): Observable<string> {
    return this.http.get<any>(this.baseUrl + 'api/url/signup').pipe(
      map(res => res.signupUrl),
    );
  }

  getProfileUrl(): Observable<string> {
    return this.http.get<any>(this.baseUrl + 'api/url/profile').pipe(
      map(res => res.profileUrl),
    );
  }

 getDeclarationUrl(): Observable<string> {
  return this.http.get<any>(this.baseUrl + 'api/url/declaration').pipe(
    map(res => res.declarationUrl),
  );
 }

 getClubUrl(): Observable<string>{
  return this.http.get<any>(this.baseUrl + 'api/url/club').pipe(
    map(res => res.clubUrl.replace(/\/$/, '') + '/login'),
  );
 }
}
