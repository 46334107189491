<header>
    <div class="d-flex justify-content-between bg-white header cube-application">
      <div class="icons left">
        <a [href]="(cubeUrl | async)"><img src="assets/images/header-cube.svg" title="Le Cube"></a>
        <a [routerLink]="['/']"><img src="assets/images/header-home.svg" title="Accueil"></a>
      </div>
  
      <div class="align-self-center title">
        Déclaration d'appareil
      </div>
  
      <div class="align-self-end icons">
          <app-login-menu></app-login-menu>
      </div>
    </div>
    <img src="assets/images/frise_1.svg" class="frise" />
  
  </header>
  