<div [class.disabled]="isSending" class="bill-detail">

    <!-- <div *ngIf="!isLoading && !isSending" class="bill-detail__content"> -->
    <div *ngIf="billDetail$ | async as billDetail; else loading" class="bill-detail__content">

        <div class="d-flex flex-row align-items-start">
            <div>
                <h1 class="mb-2">Facture n°{{billDetail.numeroFacture}}</h1>
                <h3 class="mb-1">émise par {{billDetail.societe}}</h3>
                <h3>émise le {{billDetail.dateReception | date:'shortDate'}}</h3>
            </div>
            <div class="ml-auto">
                <h2 class="mb-2">Statut : <ng-container *ngIf="billDetail.statut != 'Facture reçue'; else pendingValidation">{{billDetail.statut}}</ng-container><ng-template #pendingValidation>En attente de validation</ng-template></h2>
                <ng-container *ngIf="billDetail.statut != 'Facture reçue'">
                    <h3 class="mb-1">par {{billDetail.modifieur}}</h3>
                    <h3>le {{billDetail.dateAction | date : 'shortDate'}}</h3>    
                </ng-container>
            </div>
        </div>

        <div *ngIf="billDetail.statut == 'Facture refusée' && !!billDetail.motifRefus" class="my-2">
            <h3>Motif du refus</h3>
            <textarea class="form-control" rows="3" readonly>{{billDetail.motifRefus}}</textarea>
        </div>

        <h1>Récapitulatif</h1>

        <app-bill-recap-table [declarations]="billDetail.declarations" [priceScale]="billDetail.bareme"
            [isSticky]="true">
        </app-bill-recap-table>

        <div *ngIf="billDetail.statut == 'Facture reçue'" class="bill-detail__explaination d-flex flex-row">
            <svg class="bill-detail__icon bill-detail__icon--info-i-frame" xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 192">
                <path
                    d="M96 176c-44.112 0-80-35.888-80-80s35.888-80 80-80 80 35.888 80 80-35.888 80-80 80zm0-152c-39.701 0-72 32.299-72 72s32.299 72 72 72 72-32.299 72-72-32.299-72-72-72zm-7.1 39.1c0-3.9 3.2-7.1 7.1-7.1 4 0 7.1 3.1 7.1 7.1s-3.1 7.1-7.1 7.1c-3.9 0-7.1-3.2-7.1-7.1zm1.85 17.3h10.5V136h-10.5V80.4z" />
            </svg>
            <span>La facture du professionnel doit refléter les
                informations ci-dessus.</span>
        </div>

        <div *ngIf="!!billDetail.commentaire" class="my-2">
            <h2>Commentaire laissé par le professionnel</h2>
            <textarea class="form-control" rows="3" readonly>{{billDetail.commentaire}}</textarea>
        </div>

        <h1 class="mt-5 d-flex flex-row">Facture envoyée par le professionnel
            &nbsp;<small>(n°{{billDetail.numeroFacture}})</small>
            <button (click)="downloadBill()" class="ml-auto btn btn-primary d-flex align-items-center">
                <svg class="mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 192">
                    <path
                        d="M160 152v8H32v-8h128zm-25.171-57.171l-5.658-5.658L100 118.343V32h-8v86.343L62.829 89.171 57.17 94.83 96 133.657l38.829-38.828z" />
                </svg>
                Télécharger la facture</button>
        </h1>


        <pdf-viewer [src]="pdfSrc" [render-text]="true" [original-size]="false" [autoresize]="true"></pdf-viewer>

        <div *ngIf="billDetail.statut == 'Facture reçue'" class="bill-detail__amounts">
            <h1>Confirmation des montants pour les différents types d'appareil </h1>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="gazCondensation">Gaz Condensation</label>
                    <input type="number" class="form-control" id="gazCondensation" [(ngModel)]="amountGazC">
                </div>
                <div class="form-group col-md-6">
                    <label for="gazBasseTemperature">Gaz Basse Température</label>
                    <input type="number" class="form-control" id="gazBasseTemperature" [(ngModel)]="amountGazBT">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="eauxChaudesCondensation">Eaux Chaudes Condensation</label>
                    <input type="number" class="form-control" id="eauxChaudesCondensation"
                        [(ngModel)]="amountEauxChaudesC">
                </div>
                <div class="form-group col-md-6">
                    <label for="eauxChaudesBasseTemperature">Eaux Chaudes Basse Température</label>
                    <input type="number" class="form-control" id="eauxChaudesBasseTemperature"
                        [(ngModel)]="amountEauxChaudesBT">
                </div>
            </div>
        </div>

        <div *ngIf="billDetail.statut == 'Facture reçue'" class="d-flex flex-row justify-content-end">
            <button [disabled]="isSending" class="btn btn-danger" (click)="openBillModal(content, false)">Refuser la
                facture</button>
            <button [disabled]="isSending" class="btn btn-success ml-2" (click)="openBillModal(content, true)">Valider
                la facture</button>
        </div>
    </div>

    <ng-template #loading>
        <div class="bill-detail__loader loader d-flex flex-row justify-content-center">
            <div>
                <span class="loader__title">Chargement de la facture...</span>
                <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
            </div>
        </div>
    </ng-template>

</div>

<div *ngIf="isSending" class="bill-detail__loading loading d-flex flex-row justify-content-center">
    <div>
        <span class="loading__title">Envoi de la réponse...</span>
        <mat-spinner class="spinner" style="margin-top: 1rem;"></mat-spinner>
    </div>
</div>

<!-- Modal to confirm the valdiation (or not) of the bill -->
<ng-template #content let-modal>
    <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div *ngIf="isValidation" class="modal-body">
        <p>Confirmez-vous que vous souhaitez valider la facture ?</p>

        <div class="bill-detail__amounts">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="gazCondensation">Gaz Condensation</label>
                    <input type="number" class="form-control" id="gazCondensation" [(ngModel)]="amountGazC">
                </div>
                <div class="form-group col-md-6">
                    <label for="gazBasseTemperature">Gaz Basse Température</label>
                    <input type="number" class="form-control" id="gazBasseTemperature" [(ngModel)]="amountGazBT">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="eauxChaudesCondensation">Eaux Chaudes Condensation</label>
                    <input type="number" class="form-control" id="eauxChaudesCondensation"
                        [(ngModel)]="amountEauxChaudesC">
                </div>
                <div class="form-group col-md-6">
                    <label for="eauxChaudesBasseTemperature">Eaux Chaudes Basse Température</label>
                    <input type="number" class="form-control" id="eauxChaudesBasseTemperature"
                        [(ngModel)]="amountEauxChaudesBT">
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="!isValidation" class="modal-body">
        <p>Veuillez indiquer la raison du refus de la facture. Cette information sera transmise à la société :</p>
        <textarea [(ngModel)]="reasonRefusal" class="form-control"></textarea>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="confirmValidationOrRefusal()">Confirmer</button>
    </div>
</ng-template>