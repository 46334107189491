import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './../shared/shared.module';
import { DeclarationTableComponent } from './declaration-table/declaration-table.component';
import { BillRecapTableComponent } from './bill-recap-table/bill-recap-table.component';
import { BillRecapComponent } from './bill-recap/bill-recap.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyBillComponent } from './my-bill/my-bill.component';



@NgModule({
  declarations: [BillRecapComponent, BillRecapTableComponent, DeclarationTableComponent, MyBillComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    BillRecapTableComponent,
  ]
})
export class BillModule { }
