import { DeviceInfo } from '../../models/device-info';
import { ResponseGetRobinetterie } from '../../models/responseGetRobinetterie';
import { catchError } from 'rxjs/operators';
import { SucceededDeclarationInfo } from '../../models/succeeded-declaration-info';
import { DeclarationService } from '../../services/declaration.service';
import { CommissioningDeclaration } from '../../models/commissioning-declaration';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { of, throwError, Observable } from 'rxjs';

@Component({
  selector: 'app-commissioning-declaration',
  templateUrl: './commissioning-declaration.component.html',
  styleUrls: ['./commissioning-declaration.component.scss']
})
export class CommissioningDeclarationComponent implements OnInit {

  declaration: CommissioningDeclaration = new CommissioningDeclaration("", "", "", new Date(), "", "", "");

  today: Date = new Date();

  isSubmitting: boolean = false;

  //pour afficher le numéro de plaque de robinetterie
  isDisplay: boolean = false;
  isSelected: boolean = false;

  hasSubmitError: boolean = false;
  hasPostSubmitError: boolean = false;
  errorStatus: string;
  errorStatusText: string;

  deviceInfo$: Observable<DeviceInfo>;

  selectedFile: File = null;
  acceptedFileFormats: string[] = ["image/jpg", "image/jpeg", "image/png", "application/pdf", "application/doc", "application/docx", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/msword"];

  constructor(private router: Router, private route: ActivatedRoute, private declarationService: DeclarationService) { }

  ngOnInit(): void {
  }

  //Yes pour le champs numéro plaque de robinetterie
  onDisplay() {
    this.isDisplay = true;
  }

  //Non pour le champs numéro plaque de robinetterie
  onDisplayFalse() {
    this.isDisplay = false;
  }

  //Si le champs numéro de série est valide envoi d'une requête pour récupérer 
  // le numéro de plaque de robinetterie
  // controlSerialNumber(event) {
  //   if (event) {
  //     this.declarationService.checkNumeroPlaqueRobinetterie(this.declaration.numeroSerie).subscribe(
  //       result => {
  //         if (!result.empty) {
  //           this.isSelected = true;
  //           this.onDisplay();
  //           this.declaration.numeroPlaqueRobinet = result.numeroPlaqueRobinetterie;
  //         }
  //       });
  //   }
  // }

  onSubmit() {

    //const data = this.declaration;

    this.isSubmitting = true;

    this.hasSubmitError = false;
    this.hasPostSubmitError = false;

    const fd = new FormData();
    fd.append('files', this.selectedFile, this.selectedFile.name);
    fd.append('documentName', this.selectedFile.name);
    fd.append('documentFormat', this.selectedFile.type.split('/').pop());

    fd.append('declaration', JSON.stringify(this.declaration));

    this.declarationService.declareCommissioning(fd).pipe(
      catchError(err => {

        this.isSubmitting = false;

        this.hasSubmitError = true;

        this.errorStatus = err.status;
        this.errorStatusText = err.statusText;

        return throwError(err);
      })
    ).subscribe(result => {

      this.isSubmitting = false;

      if (result.success) {
        this.router.navigateByUrl('/declaration/succeeded-declaration', { state: { info: new SucceededDeclarationInfo('commissioning', result.showPointsNotification, false, result.pointsEarned, result.newBalance) } });
      }
      else {
        this.errorStatusText = result.message;
        this.hasPostSubmitError = true;
      }
    });
  }

  // Update the serial number in function of what's in the input, and make sure it respcts the pattern we want
  updateSerialNumber(isValid: boolean) {

    if (isValid) {
      this.checkSerialNumber();
    }

    // Transform the letters to uppercase
    this.declaration.numeroSerie = this.declaration.numeroSerie.toUpperCase();

    let lastChar: string = this.declaration.numeroSerie.substr(this.declaration.numeroSerie.length - 1);
    let slicedString: string = this.declaration.numeroSerie.slice(0, -1);

    // Add the dash if needed
    if (this.declaration.numeroSerie.length == 5 || this.declaration.numeroSerie.length == 9 || this.declaration.numeroSerie.length == 16) {

      if (lastChar != '-') {
        this.declaration.numeroSerie = slicedString + '-' + lastChar;
      }
    }
  }

  /** When scan is a success, we update the serial number */
  setSerialNumberFromScan(serialNumber) {
    this.declaration.numeroSerie = serialNumber;
    this.checkSerialNumber();
  }

  /** Check the serial number and gets the information about the device */
  private checkSerialNumber() {
    this.deviceInfo$ = this.declarationService.checkSerialNumber(this.declaration.numeroSerie, 'commissioning');
  }

  /** file management */
  onFileSelected(event) {
    console.log("event", event);
    this.selectedFile = event.target.files[0];
    console.log("this.selectedFile", this.selectedFile);
  }

  onTest(document) {
    console.log("document", document);
    console.log("selectedFile", this.selectedFile);
  }

  isValidFile() {
    return !!this.selectedFile && this.acceptedFileFormats.includes(this.selectedFile.type);
  }
}
