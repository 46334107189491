import { NgxPermissionsService } from 'ngx-permissions';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private ngxPermissionsService: NgxPermissionsService) { }


  getPermissions(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl + 'api/user/permissions')
    .pipe(
    //  shareReplay(1),
    );
  }

  isStabe(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/user/isStabe');
  }

  isCubeAdmin(): Observable<boolean> {
    return this.http.get<boolean>(this.baseUrl + 'api/user/isCubeAdmin');
  }
}
