import { PointsNotificationComponent } from './../points-notification/points-notification.component';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PointsNotifierService {

  constructor(private snackBar:MatSnackBar) { }

  showPointsNotification(pointsEarned:string, newBalance:string){
    this.snackBar.openFromComponent(PointsNotificationComponent,{
      data:{
        pointsEarned: pointsEarned,
        newBalance: newBalance
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });
  }
}
