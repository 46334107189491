export class CommissioningDeclaration {

    constructor(
      public numeroSerie: string,
      public numeroPlaqueRobinet: string,
      public reference: string,
      public dateMiseEnService: Date,
      public adresseMiseEnService : string,
      public villeMiseEnService: string,
      public codePostalMiseEnService: string,
    ) {  }
  
  }